import { savePDF } from "@progress/kendo-react-pdf";

class DocService {
  createPdf = (html, user) => {  
    console.log("here")  
    savePDF(html, {
      paperSize: "A2",
      fileName: `${user.CustomerName}`,
      margin: 3,
    });
  };
}

const Doc = new DocService();
export default Doc;
