
import Profile1 from "../assets/img/team/profilePic1.jpg"
import Profile2 from "../assets/img/team/profile-picture-2.jpg"
import Profile3 from "../assets/img/team/profile-picture-3.jpg"
import Profile4 from "../assets/img/team/profile-picture-4.jpg"
import Profile5 from "../assets/img/team/profile-picture-5.jpg"

export default [
    {
        "id": 1,
        "read": false,
        "image": Profile1,
        "sender": "User 1",
        "time": "a few moments ago",
        "link": "#",
        "message": `Since you are the first user, no message so far. come back tomorrow at 12:30 AM.`
    },
    {
        "id": 2,
        "read": false,
        "image": Profile2,
        "sender": "User 2",
        "time": "2 hrs ago",
        "link": "#",
        "message": `Send me some message and go out .`
    },
    {
        "id": 3,
        "read": false,
        "image": Profile3,
        "sender": "Who am I",
        "time": "5 hrs ago",
        "link": "#",
        "message": `Why are readng this. this is my message".`
    },
    {
        "id": 4,
        "read": true,
        "image": Profile4,
        "sender": "Name theriyathu",
        "time": "1 day ago",
        "link": "#",
        "message": `Have a Good Day`
    },
    {
        "id": 5,
        "read": true,
        "image": Profile5,
        "sender": "Boss",
        "time": "2 days ago",
        "link": "#",
        "message": `this is secret nessage`
    },
]