import React, { Component, Fragment } from 'react'
import { LoadingSpinner } from '@pearson-components/elements-sdk/build/dist.elements-sdk';
import "../scss/volt/loader.scss"

export default class Loader extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {

        return (
            <Fragment>
                {
                    this.props.loader ?
                        <div className="pe-loader-layout" role="alert" aria-live="assertive" ><div className="pe-loader-box"><LoadingSpinner></LoadingSpinner><div className="pe-loader-caption" >Loading...</div></div></div> : null
                }
            </Fragment>
        )
    }
}