"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getuser = getuser;
exports.Loginauthentication = Loginauthentication;
exports.UploadImage = UploadImage;
exports.uploadUser = uploadUser;
exports.AddUpdateUser = AddUpdateUser;
exports.UploadImages = UploadImages;
exports.deleteUser = deleteUser;
exports.addUpdateTree = addTree;
exports.deleteTree = deleteTree;
exports.uploadTreeImage = uploadTreeImage;
var axios = require('axios')

var _reactToastify = require("react-toastify");

require("react-toastify/dist/ReactToastify.css");

// API for Cmd Execution
function getuser(path) {
  return new Promise(function (resolve, reject) {
    axios.get(path).then(function (response) {
      if (response.data.isSuccess) {
        resolve(response.data);
      }
    })["catch"](function (err) {
      reject(err);
      console.log(err);
    });
  });
}


function Loginauthentication(path, email, password) {
  return new Promise(function (resolve, reject) {
    axios.post(path, { email: email, password: password }).then((response) => {
      if (response.data.isSuccess) {
        resolve(response.data);
      } else {
        resolve(response.data);
      }
    }).catch((err) => {
      console.log("error in login", err)
      reject(err)
    })
  });
}
function UploadImage(path, Imageinfo) {
  return new Promise(function (resolve, reject) {
    console.log("Imageinfo", Imageinfo)
    axios.post(path, Imageinfo).then((response) => {
      if (response.data.isSuccess) {
        resolve(response.data);
      } else {
        resolve(response.data);
      }
    }).catch((err) => {
      reject(err)
    })
  });
}

function uploadUser(path, userinfo) {
  return new Promise(function (resolve, reject) {
    axios.post(path, userinfo).then((response) => {
      if (response.data.isSuccess) {
        resolve(response.data);
      } else {
        resolve(response.data);
      }
    }).catch((err) => {
      reject(err)
    })
  });
}
function AddUpdateUser(path, userinfo) {
  return new Promise(function (resolve, reject) {
    axios.post(path, userinfo).then((response) => {
      if (response.data.isSuccess) {
        resolve(response.data);
      } else {
        resolve(response.data);
      }
    }).catch((err) => {
      reject(err)
    })
  });
}
function UploadImages(path, Imageinfo) {
  return new Promise(function (resolve, reject) {
    axios.post(path, Imageinfo).then((response) => {
      if (response.data.isSuccess) {
        resolve(response.data);
      } else {
        resolve(response.data);
      }
    }).catch((err) => {
      reject(err)
    })
  });
}
function deleteUser(path, user) {
  return new Promise(function (resolve, reject) {
    axios.post(path, user).then((response) => {
      if (response.data.isSuccess) {
        resolve(response.data);
      } else {
        resolve(response.data);
      }
    }).catch((err) => {
      reject(err)
    })
  });
}

function addTree(path, treeInfo) {
  return new Promise(function (resolve, reject) {
    axios.post(path, treeInfo).then((response) => {
      if (response.data.isSuccess) {
        resolve(response.data);
      } else {
        resolve(response.data);
      }
    }).catch((err) => {
      reject(err)
    })
  });
} 

function deleteTree(path, user) {
  return new Promise(function (resolve, reject) {
    axios.post(path, user).then((response) => {
      if (response.data.isSuccess) {
        resolve(response.data);
      } else {
        resolve(response.data);
      }
    }).catch((err) => {
      reject(err)
    })
  });
}

function uploadTreeImage(path, Imageinfo) {
  return new Promise(function (resolve, reject) {
    console.log("Imageinfo", Imageinfo)
    axios.post(path, Imageinfo).then((response) => {
      if (response.data.isSuccess) {
        resolve(response.data);
      } else {
        resolve(response.data);
      }
    }).catch((err) => {
      reject(err)
    })
  });
}