import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faHome,
    faSearch,
    faEllipsisH,
    faEdit,
    faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import {
    Table,
    Card,
    Form,
    Button,
    Breadcrumb,
    InputGroup,
    Dropdown,
    Pagination
} from "@themesberg/react-bootstrap";
import { Routes } from "../routes";
import { Link } from "react-router-dom";
import { getuser, deleteTree } from "../Commanservice/commanservice.dev";
import { ToastContainer, toast } from "react-toastify";
import config from "../config/axios/config.json";
import Loader from "../components/loader";
const Mode = config.Application.mode;
const APIURL = config["CommonConstants"].web[Mode];

export class TreeDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            TreeList: [],
            filteredTreeList: [],
            DownloadType: "Single",
            CurrentlyChecked: "",
            Loading: true,
            noofPages: 1,
            perPage: 50,
            activePage: 1,
            pageItems: [],
            isSearch: false,
            searchList: [],
            defaultPage: [{
                pageNo: 'first',
                isActive: false
            }, {
                pageNo: 'prev',
                isActive: false
            }, {
                pageNo: 'next',
                isActive: false
            }, {
                pageNo: 'last',
                isActive: false
            }]
        };
    }

    componentDidMount() {
        getuser(`${APIURL.API_URL}/QRCode/read`)
            .then((response) => {
                if (response.isSuccess) {
                    let pageitems = [];
                    this.setState({
                        TreeList: response.data.tree,
                        Loading: false,
                        filteredTreeList: this.setTreePagination(response.data.tree, 1),
                        noofPages: Math.ceil(response.data.tree.length / this.state.perPage)
                    })
                    for (let i = 1; i <= Math.ceil(response.data.tree.length / this.state.perPage); i++) {
                        pageitems.push({
                            pageNo: i,
                            isActive: i == 1 ? true : false
                        })
                    }
                    this.setState({ pageItems: pageitems })
                    this.setPageItem(1, false, '')

                }
            })
            .catch((e) => {
                console.log("Error", e);
            });
    }
    setTreePagination = (TreeList, activePage) => {
        let { perPage } = this.state
        let pageNo = activePage;
        let filteredTreeList = TreeList.slice(((pageNo - 1) * perPage), (((pageNo - 1) * perPage) + perPage))
        return filteredTreeList;
    }
    setPageItem = (pageNo, isSearch, Data) => {
        let pageitems = [];
        let { pageItems, defaultPage } = this.state
        let pages = defaultPage;
        let tempItem = pageItems;
        if (!isSearch) {
            tempItem.map(x => {
                if (x.pageNo == pageNo) {
                    x.isActive = true
                }
                else {
                    x.isActive = false
                }
            })
        }
        else {
            for (let i = 1; i <= Math.ceil(Data.length / this.state.perPage); i++) {
                pageitems.push({
                    pageNo: i,
                    isActive: i == 1 ? true : false
                })
            }
        }
        pages.map(x => x.isActive = false)
        this.setState({
            activePage: parseInt(pageNo),
            filteredTreeList: isSearch ? this.setTreePagination(Data, pageNo) : this.setTreePagination(this.state.TreeList, pageNo),
            pageItems: isSearch ? pageitems : tempItem,
            noofPages: isSearch ? Math.ceil(this.setTreePagination(this.state.TreeList, pageNo).length / this.state.perPage) : this.state.noofPages,
            defaultPage: pages
        })
    }
    searchTree = (e) => {
        const searchTree = e.target.value.toLowerCase();
        if (searchTree == "")
            this.setState({ isSearch: false, filteredTreeList: this.state.TreeList, searchList: [] });
        else {
            let filteredTreeList = this.state.TreeList;
            filteredTreeList = filteredTreeList.filter((x) =>
                (x.TreeName.toLowerCase().includes(searchTree) ||
                    x.BotanicalName.toLowerCase().includes(searchTree) ||
                    x.TamilName.toLowerCase().includes(searchTree) ||
                    x.TreeDescription.toLowerCase().includes(searchTree) ||
                    x.NameInTamil.toLowerCase().includes(searchTree)
                )
            );
            this.setState({ isSearch: true, searchList: filteredTreeList, filteredTreeList: this.setTreePagination(filteredTreeList, this.state.activePage) });
            this.setPageItem(1, true, filteredTreeList)
            console.log("filtered", filteredTreeList);
        }
    };
    deleteData = (item) => {
        console.log(item);
        this.setState({ Loading: true })
        deleteTree(`${APIURL.API_URL}/QRCode/deleteTree`, item)
            .then((response) => {
                if (response.isSuccess) {
                    this.componentDidMount();
                    this.setState({ Loading: false })
                    toast.success(response.message);
                }
                else {
                    this.componentDidMount();
                    this.setState({ Loading: false })
                    if (response.message.includes('Client')) {
                        toast.warning(response.message);
                    }
                    else {
                        toast.error(response.message);
                    }
                }
            })
            .catch((e) => {
                console.log("Error", e);
            });
    };
    defaultPagination = (pageType, activePage) => {
        let { pageItems, defaultPage } = this.state
        let tempItem = defaultPage, pageitems = pageItems;
        this.setState({ activePage: activePage, filteredTreeList: this.setTreePagination(this.state.TreeList, activePage) })
        pageitems.map(x => x.isActive = false)
        tempItem.map((x, ind) => {
            if (pageType == 'first') {
                if (ind == 0) {
                    tempItem[0].isActive = true
                }
                else {
                    tempItem[ind].isActive = false
                }
            }
            else if (pageType == 'prev') {
                if (ind == 1) {
                    tempItem[1].isActive = true
                }
                else {
                    tempItem[ind].isActive = false
                }
            }
            else if (pageType == 'next') {
                if (ind == 2) {
                    tempItem[2].isActive = true
                }
                else {
                    tempItem[ind].isActive = false
                }
            }
            else if (pageType == 'last') {
                if (ind == 3) {
                    tempItem[3].isActive = true
                }
                else {
                    tempItem[ind].isActive = false
                }
            }
        })

        this.setState({ defaultPage: tempItem, pageItems: pageitems })
    }
    render() {
        let { TreeList, filteredTreeList, perPage, activePage } = this.state
        return (
            <>
                <Loader loader={this.state.Loading}></Loader>
                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-4">
                    <div className="d-block mb-4 mb-md-0">
                        <Breadcrumb
                            className="d-none d-md-inline-block"
                            listProps={{
                                className: "breadcrumb-dark breadcrumb-transparent",
                            }}
                        >
                            {/* <Breadcrumb.Item>
                                <FontAwesomeIcon icon={faHome} />
                            </Breadcrumb.Item> */}
                            <Breadcrumb.Item
                                onClick={() => (window.location.href = "/dashboard/overview")}
                            >
                                <FontAwesomeIcon icon={faHome} />  Home
              </Breadcrumb.Item>
                            <Breadcrumb.Item active>Tree Details</Breadcrumb.Item>
                        </Breadcrumb>
                        <h4>Tree Details</h4>
                        {/* <p className="mb-0">Your web analytics dashboard template.</p> */}
                    </div>
                </div>

                <div
                    style={{ display: "flex", width: '50%' }}
                    className="table-settings mb-4 Displaysettings"
                >
                    <InputGroup>
                        <InputGroup.Text>
                            <FontAwesomeIcon icon={faSearch} />
                        </InputGroup.Text>
                        <Form.Control
                            type="text"
                            onChange={(e) => this.searchTree(e)}
                            placeholder="Search"
                        />
                    </InputGroup>
                </div>

                <Card
                    border="light"
                    className="table-wrapper table-responsive shadow-sm"
                >
                    <Card.Body className="pt-0">
                        <Table hover className="user-table align-items-center">
                            <thead>
                                <tr>
                                    <th className="border-bottom">S.No </th>
                                    <th className="border-bottom">Tree Name</th>
                                    <th className="border-bottom">Botanical Name</th>
                                    <th className="border-bottom">Tamil Name</th>
                                    <th className="border-bottom">Name In Tamil</th>
                                    <th className="border-bottom">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.filteredTreeList.map((t, index) => (
                                    <tr key={t.ID}>
                                        <td>{(this.state.activePage - 1) * this.state.perPage + index + 1}</td>
                                        <td>{t.TreeName}</td>
                                        <td>{t.BotanicalName}</td>
                                        <td>{t.TamilName}</td>
                                        <td>{t.NameInTamil}</td>
                                        <td>
                                            <div style={{ display: "flex" }}>
                                                <Dropdown>
                                                    <Dropdown.Toggle
                                                        as={Button}
                                                        split
                                                        variant="link"
                                                        className="text-dark m-0 p-0"
                                                    >
                                                        <span className="icon icon-sm">
                                                            <FontAwesomeIcon
                                                                icon={faEllipsisH}
                                                                className="icon-dark"
                                                            />
                                                        </span>
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        <Dropdown.Item onClick={() => {
                                                            sessionStorage.setItem(
                                                                "editTreeData",
                                                                JSON.stringify(t)
                                                            );
                                                            sessionStorage.setItem("isTreeEdit", true);
                                                            window.location.href = "/addTree"
                                                        }}>
                                                            <FontAwesomeIcon icon={faEdit} className="me-2" />
                                                            {" "}
                                                            Edit{" "}

                                                        </Dropdown.Item>
                                                        <Dropdown.Item className="text-danger">
                                                            <FontAwesomeIcon
                                                                icon={faTrashAlt}
                                                                className="me-2"
                                                            />{" "}
                                                            <a
                                                                onClick={() => {
                                                                    this.deleteData(t);
                                                                }}
                                                            >
                                                                Remove
                              </a>
                                                        </Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                        {this.state.filteredTreeList.length > 0 ?
                            <Pagination>
                                <Pagination.First onClick={(e) => this.defaultPagination('first', 1, e)} active={this.state.defaultPage[0].isActive} />
                                <Pagination.Prev onClick={(e) => this.defaultPagination('prev', this.state.activePage - 1)} active={this.state.defaultPage[1].isActive} />
                                {this.state.pageItems.map((p, index) => (
                                    <Pagination.Item key={index} onClick={(e) => this.setPageItem(e.currentTarget.text ? e.currentTarget.text : 1, this.state.isSearch ? true : false, this.state.isSearch ? this.state.searchList : '')} active={p.isActive}>
                                        {p.pageNo}
                                    </Pagination.Item>
                                ))}
                                <Pagination.Next onClick={(e) => this.defaultPagination('next', this.state.activePage + 1)} active={this.state.defaultPage[2].isActive} />
                                <Pagination.Last onClick={(e) => this.defaultPagination('last', this.state.noofPages)} active={this.state.defaultPage[3].isActive} />
                            </Pagination> : null}
                    </Card.Body>
                    <ToastContainer
                        autoClose={3000}
                        hideProgressBar={true}
                        limit={0}
                        draggable={false}
                        position={toast.POSITION.TOP_RIGHT}
                    ></ToastContainer>
                </Card>
            </>
        );
    }
}

export default TreeDetails;
