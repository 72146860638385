import React, { Component } from "react";
import { FileUploader } from "react-drag-drop-files";
import {
  Col,
  Row,
  Card,
  Form,
  Button,
  InputGroup,
  Container,
} from "@themesberg/react-bootstrap";
import img1 from "../assets/img/image1.jpg";
// import img2 from "../assets/img/leaves.png";
import img3 from "../assets/img/image3.JPG";
import img4 from "../assets/img/image4.JPG";
import config from "../config/axios/config.json";
import { getuser } from "../Commanservice/commanservice.dev";
const Mode = config.Application.mode;
const APIURL = config["CommonConstants"].web[Mode];
// const Cryptr = require("cryptr");

export default class Page extends Component {
    constructor(props) {
        super(props);
        this.state = {
            FilteredUserList:[]
        };
      }

  componentDidMount() {
    let URl = window.location.href;
    // const cryptr = new Cryptr("myTotalySecretKey");
    // const decryptedString = cryptr.decrypt(URl.split("?")[1].split("=")[1]);
    getuser(`${APIURL.API_URL}/QRCode/read`)
      .then((response) => {
        if (response.isSuccess) {
          this.setState({
            FilteredUserList: response.data.users.filter(
              (a, b) => (a.ID = parseInt(10))
            ).map((a,b) => a.Treeinfo = response.data.tree.filter((x,y) =>x.ID ==a.TreeName )[0]),
          });
        }
      })
      .catch((e) => {
        console.log("Error", e);
      });
  }

  render() {
      console.log("Filtered List",this.state.FilteredUserList)
    return (
      <div class="container1">
        <div class="img">
          <img class="image1" src={img1} alt="flower Image" />
          <div class="container2">
            <div class="nameDetails">
              <div class="img1dear">Dear,</div>
              <div class="img1name">Amy Thomas</div>
              <div class="img1text">
                Here’s the digital space of the tree we have planted on your
                behalf. You can visit this space any time of the day, 365 days
                of the year.
              </div>
            </div>
            <div class="container21">
              <div class="secondImage">
                <div class="img2text">
                  <table class="imgTable">
                    <tr>
                      <td class="trLeft">Company name</td>
                      <td class="colon">:</td>
                      <td class="trRight">Castod Tree</td>
                    </tr>
                    <tr>
                      <td class="trLeft">Botanical Name</td>
                      <td class="colon">:</td>
                      <td class="trRight">Senna siamea</td>
                    </tr>
                    <tr>
                      <td class="trLeft">Tree No.</td>
                      <td class="colon">:</td>
                      <td class="trRight">INTGG1791</td>
                    </tr>
                    <tr>
                      <td class="trLeft">Planted during</td>
                      <td class="colon">:</td>
                      <td class="trRight">November 2019</td>
                    </tr>
                  </table>
                  <div class="c21text">
                    Senna siamea is native to South and SoutheastAsia and is
                    also known as Siamese cassia, kassod tree, cassod tree and
                    cassia tree. This plant has medicinal value and it contains
                    a compound named barakol. The leaves, tender pods and seeds
                    are edible. Other uses include as fodder plant, in
                    intercropping systems, windbreaks, and shelter belts. As a
                    hardwood, it is used for ornamentation on instruments
                    (ukeleles and guitars) and decorative products
                  </div>
                </div>
                <div class="imagePart">
                  <img src={img4} class="image2" alt="flower Image" />
                </div>
              </div>
              <input type="button" class="button1" value="Click to Zoom" />
              <input type="button" class="button1" value="Click to Zoom" />
            </div>
            <div class="mapTop">
              <div class="normalText">
                Integra's Private Forest loacated at here{" "}
              </div>
            </div>
            <img src={img3} class="image3" width="100%" alt="flower Image" />
          </div>
        </div>
        <div class="thirdImage">
          <div class="lowerContent">
            <div class="GreenQuotes">
              {/* <img class="leaves" src={img2} /> <p></p> */}
              <div class="greenColor">Green </div>
              <div class="QuoteColor">Quotient!</div>
            </div>
            <div class="listBorder">
              <ul>
                <li class="List">
                  Trees absorb the sun’s energy & account for 50% of energy
                  trapped by all on Earth
                </li>
                <li class="list">
                  A single mature tree can absorb as much as 48 pounds of carbon
                  dioxide per year
                </li>
                <li class="list">
                  A tree releases enough oxygen back into the atmosphere to
                  support 2 human every year
                </li>
                <li class="list">
                  Trees sequester CO2 during photosynthesis & act as carbon
                  sinks the greenhouse effect
                </li>
                <li class="list">
                  Trees intercept water runoff, impede soil erosion & ensure
                  groundwater supply replenishment
                </li>
              </ul>
            </div>
          </div>
          <div class="icons">
            <svg
              class="imageIcons"
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              width="100"
              height="100"
              viewBox="0 0 48 48"
            >
              <path d="M 24 4 C 12.972066 4 4 12.972074 4 24 C 4 35.027926 12.972066 44 24 44 C 35.027934 44 44 35.027926 44 24 C 44 12.972074 35.027934 4 24 4 z M 24 7 C 33.406615 7 41 14.593391 41 24 C 41 32.380773 34.967178 39.306373 27 40.720703 L 27 29 L 30.625 29 C 31.129 29 31.555188 28.623047 31.617188 28.123047 L 31.992188 25.123047 C 32.028188 24.839047 31.938047 24.553891 31.748047 24.337891 C 31.559047 24.122891 31.287 24 31 24 L 27 24 L 27 20.5 C 27 19.397 27.897 18.5 29 18.5 L 31 18.5 C 31.552 18.5 32 18.053 32 17.5 L 32 14.125 C 32 13.607 31.604844 13.174906 31.089844 13.128906 C 31.030844 13.123906 29.619984 13 27.833984 13 C 23.426984 13 21 15.616187 21 20.367188 L 21 24 L 17 24 C 16.448 24 16 24.447 16 25 L 16 28 C 16 28.553 16.448 29 17 29 L 21 29 L 21 40.720703 C 13.032822 39.306373 7 32.380773 7 24 C 7 14.593391 14.593385 7 24 7 z"></path>
            </svg>

            <svg
              class="imageIcons"
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              width="50"
              height="50"
              viewBox="0 0 30 30"
            >
              <path d="M15,3C8.373,3,3,8.373,3,15c0,6.627,5.373,12,12,12s12-5.373,12-12C27,8.373,21.627,3,15,3z M10.496,8.403 c0.842,0,1.403,0.561,1.403,1.309c0,0.748-0.561,1.309-1.496,1.309C9.561,11.022,9,10.46,9,9.712C9,8.964,9.561,8.403,10.496,8.403z M12,20H9v-8h3V20z M22,20h-2.824v-4.372c0-1.209-0.753-1.488-1.035-1.488s-1.224,0.186-1.224,1.488c0,0.186,0,4.372,0,4.372H14v-8 h2.918v1.116C17.294,12.465,18.047,12,19.459,12C20.871,12,22,13.116,22,15.628V20z"></path>
            </svg>

            <svg
              class="imageIcons"
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              width="50"
              height="50"
              viewBox="0 0 50 50"
            >
              <path d="M25,2C12.317,2,2,12.317,2,25s10.317,23,23,23s23-10.317,23-23S37.683,2,25,2z M36.237,20.524 c0.01,0.236,0.016,0.476,0.016,0.717C36.253,28.559,30.68,37,20.491,37c-3.128,0-6.041-0.917-8.491-2.489 c0.433,0.052,0.872,0.077,1.321,0.077c2.596,0,4.985-0.884,6.879-2.37c-2.424-0.044-4.468-1.649-5.175-3.847 c0.339,0.065,0.686,0.1,1.044,0.1c0.505,0,0.995-0.067,1.458-0.195c-2.532-0.511-4.441-2.747-4.441-5.432c0-0.024,0-0.047,0-0.07 c0.747,0.415,1.6,0.665,2.509,0.694c-1.488-0.995-2.464-2.689-2.464-4.611c0-1.015,0.272-1.966,0.749-2.786 c2.733,3.351,6.815,5.556,11.418,5.788c-0.095-0.406-0.145-0.828-0.145-1.262c0-3.059,2.48-5.539,5.54-5.539 c1.593,0,3.032,0.672,4.042,1.749c1.261-0.248,2.448-0.709,3.518-1.343c-0.413,1.292-1.292,2.378-2.437,3.064 c1.122-0.136,2.188-0.432,3.183-0.873C38.257,18.766,37.318,19.743,36.237,20.524z"></path>
            </svg>

            <svg
              class="imageIcons"
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              width="50"
              height="50"
              viewBox="0 0 64 64"
            >
              <path d="M32,6C17.641,6,6,17.641,6,32c0,14.359,11.641,26,26,26s26-11.641,26-26C58,17.641,46.359,6,32,6z M32.646,33.909	c0,5.427-3.257,10.015-9.142,10.015c-5.664,0-9.44-4.961-9.44-11.44c0-7.069,3.924-11.191,9.44-11.191	c3.886,0,6.181,1.333,7.995,4.093l-3.11,1.799c-1.073-1.706-2.369-2.357-4.589-2.357c-3.703,0-5.849,2.324-5.849,7.657	c0,4.589,2.073,7.999,5.553,7.999c3.257,0,4.701-1.798,4.701-4.744h-4.331v-3.317h8.773V33.909z M44.27,34.033v6.449h-2.821v-6.449	h-6.449v-2.821h6.449v-6.418h2.821v6.418h6.419v2.821H44.27z"></path>
            </svg>
            {/* <img class="imageIcons" src={fb} alt="facebook"/>
          
          
          <img class="imageIcons" src={tw} alt="twitter"/>
            <img class="imageIcons" src={wt} alt="whatsapp"/>
            <img class="imageIcons" src={Li} alt="linked in"/> */}
            {/* <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor"
                    class="bi bi-facebook" viewBox="0 0 16 16">
                    <path
                        d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z" />
                </svg>
                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-info"
                    viewBox="0 0 16 16">
                    <path
                        d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                </svg>
             
                <svg class="icon2" xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-twitter"
                    viewBox="0 0 16 16">
                    <path
                        d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518 3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.865.115 3.23 3.23 0 0 1-.614-.057 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z" />
                </svg>
                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-google"
                    viewBox="0 0 16 16">
                    <path
                        d="M15.545 6.558a9.42 9.42 0 0 1 .139 1.626c0 2.434-.87 4.492-2.384 5.885h.002C11.978 15.292 10.158 16 8 16A8 8 0 1 1 8 0a7.689 7.689 0 0 1 5.352 2.082l-2.284 2.284A4.347 4.347 0 0 0 8 3.166c-2.087 0-3.86 1.408-4.492 3.304a4.792 4.792 0 0 0 0 3.063h.003c.635 1.893 2.405 3.301 4.492 3.301 1.078 0 2.004-.276 2.722-.764h-.003a3.702 3.702 0 0 0 1.599-2.431H8v-3.08h7.545z" />
                </svg> */}
          </div>
          <div class="footer1">
            <div class="footerText">
              {" "}
              @ CopyRight ! Integra Software Services Pvt ltd
            </div>
            <div class="footerText2">Privacy policy ! Privacy Notice</div>
          </div>
        </div>
      </div>
    );
  }
}
