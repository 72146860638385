
import React, { Component } from "react";
import { FileUploader } from "react-drag-drop-files";
import {
  Col,
  Row,
  Card,
  Form,
  Button,
  InputGroup,
  Container,
} from "@themesberg/react-bootstrap";
import img1 from "../assets/img/INTGG2187.jpg";
import img2 from "../assets/img/img2.PNG";
import logo from "../assets/img/logo.jpeg";
import md1 from "../assets/img/ma1.PNG"
import md2 from "../assets/img/ma2.PNG"
import img3 from "../assets/img/CO2.png"
import img4 from "../assets/img/rain_drop.png"
import img5 from "../assets/img/temperature.png"
import img6 from "../assets/img/Leaf-01.png";
import curve from "../assets/img/CURVE-01.png"
import img7 from "../assets/img/farm-tree.png"
import QACode from "../assets/img/QA Code.PNG";
import { faWindows } from "@fortawesome/free-brands-svg-icons";
import config from "../config/axios/config.json";
const Mode = config.Application.mode;
const APIURL = config["CommonConstants"].web[Mode];
var QRCode = require("qrcode.react");
// const Cryptr = require("cryptr");

export default class Certificate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      FilteredUserList: [],
    };
  }

  //componentDidMount() {
  // let URl = "http://localhost:3000/#/Certificate?ID="
  // let encryptedString = "e7b75a472b65bc4a42e7b3f78833a4d00040beba796062bf7c13d9533b149e5ec3784813dc20348fdf248d28a2982df85b83d1109623bce45f08238f6ea9bd9bb5f406427b2a40f969802635b8907a0a57944f2c12f334bd081d5143a357c173a611e1b64a"
  // const cryptr = new Cryptr("myTotalySecretKey");
  // const decryptedString = cryptr.decrypt(encryptedString);
  // getuser(`${APIURL.API_URL}/QRCode/read`)
  // .then((response) => {
  //   if (response.isSuccess) {
  //     this.setState({FilteredUserList:response.data.filter((a,b) => a.ID = parseInt(decryptedString))[0]})
  //   }
  // })
  // .catch((e) => {
  //   console.log("Error", e);
  // });

  // }

  render() {
    return (
      <>
        <div class="mainContainer">
          <div class="containerInner">
            <div class="leftContainer">
              <div class="logo">
                <img src={logo} class="logoImg" alt="Italian Trulli" width={170} height={170} />
              </div>
              <div class="namePart">
                <div class="User">Dear</div>
                <div class="userName">Frank Vrancken Peeters</div>
                <div class="dots">
                  ...........................................................................
                </div>
              </div>
              <div class="centerContent">
                <div className="wishes">Wishing you a prosperous 2024!</div>
                <div class="content1 first">
                  We wish to express our sincere gratitude for your invaluable support. It
                  brings us immense joy to share that your tree, flourishing since August
                  2020, is now a proud member of Integra's Private Forestry Movement,
                  signifying our shared dedication to sustainable practices. Your partnership
                  holds a special place for us, and I look forward to nurturing this mutually
                  rewarding relationship throughout 2024 and beyond.
                </div>
              </div>

              <div class="Affiliated" style={{ display: "flex" }}>
                <div class="managingdirector">
                  <img class="mdrole" src={md1}></img><br></br>
                  <hr className="line" />
                  <span class="Role">
                    Founder, Managing Director & CEO
                  </span>
                </div>
                <div class="managingdirector1">
                  <img class="mdrole" src={md2}></img><br></br>
                  <div >
                    <hr className="line2" />
                  </div>
                  <span class="Role" > Co-founder & JMD</span>
                </div>
              </div>

            </div>
            <div class="rightContainer initial" >
              {/* <img src={img6} alt="leaf" className="leaf" /> */}
              {/* <div className="position-relative">
                <div class="bg-image rounded-7 shadow-5">
                  <svg
                    data-name="Layer 1"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 1420 120"
                    preserveAspectRatio="none"
                    style={{
                      opacity: "1",
                      width: "240%",
                      height: "160px",
                      fill: "#ffffff",
                      transform: "rotateY(0deg)",
                      position: "relative",
                      zindex: "0",
                    }}>
                    <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z"></path>
                  </svg>
                </div>
              </div> */}
              <div className="outer-circle">
                <img src={img1} class="curve" />
                {/* <div class="curve" style={{ backgroundImage: `${img1}`}}></div> */}
              </div>
            </div>
          </div>
        </div>

        {/* <!-- Second Page --> */}
        <div class="mainContainer">
          <div class="containerInner" style={{ height: "815px" }}>
            <div class="leftContainer">
              <div class="logo">
                <img src={logo} class="logoImg" alt="Italian Trulli" width={150} height={100} />
              </div>
              <div class="first-con" style={{ fontSize: "25px !", paddingBottom: "1em", paddingTop: "1em" }}>
                A remarkable species, your tree boosts the environment
                in significant ways.
              </div>
              <div class="comm_div">
                <img src={img3} alt="co2" width={60} height={60} />
                <div className="text" style={{ fontSize: "16px" }}>It actively absorbs 22 pounds of carbon dioxide and
                  provides life-sustaining oxygen.</div>
              </div>
              <div class="comm_div">
                <img src={img4} alt="droplet" width={60} height={60} />
                <div className="text" style={{ fontSize: "16px" }}>Intercepting roughly 1000 gallons of rainfall annually,
                  it contributes significantly to water conservation efforts.</div>
              </div>
              <div class="comm_div">
                <img src={img5} alt="co2" width={60} height={60} />
                <div className="text" style={{ fontSize: "16px" }}>It effectively reduces the local temperature by
                  2 to 9 degrees, creating a more pleasant atmosphere.</div>
              </div>
              <div class="QRPage">
                <p className="green-para">Millingtonia hortensis, tree jasmine or Indian cork tree, the sole
                  species in the genus Millingtonia, is a tree native to South Asia &
                  South East Asia. The tree is considered ornamental and the
                  pleasant fragrance of the flowers renders it ideal as a garden tree.
                  The wood is also used as timber and the bark is used as an inferior
                  substitute for cork.</p>

              </div>

              <div class="QR">
                <div className="margin">
                  <QRCode
                    value="item"
                    style={{
                      width: "100px",
                      height: "100px",
                      position: "relative",
                      padding: "7px",
                      backgroundColor: "#fff",
                    }}
                  ></QRCode><span class="QRcontent1">Experience your new digital certificate</span>

                </div>



              </div>
            </div>
            <div class="rightContainer page2" style={{ backgroundImage: `url(${img1})`, backgroundSize: 'cover', clipPath: "ellipse(92% 73% at 92% 51%);" }}>
             
            </div>
          </div>
        </div>
      </>
    );
  }
}
