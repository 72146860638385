import React, { useEffect, useState } from 'react';

const CircleImageCurve = (props) => {
  debugger;
  const { imagesrc } = props;
  const [base64Image, setBase64Img] = useState(null);

  useEffect(() => {
    if (imagesrc) {
      const imageUrl = imagesrc;
      //   const imageUrl = "https://qrgreencode.blob.core.windows.net/qrcode/QRCode/INTGG2878.jpg";
      const newWidth = 350; // Replace with your desired width
      const newHeight = 350; // Replace with your desired height
      try {
        imageToBase64(imageUrl + '.jpg', newWidth, newHeight)
          .then((resizedBase64) => {
            debugger;
            console.log('Resized image as Base64:', resizedBase64);
            debugger;
            setBase64Img(resizedBase64);
          })
          .catch((error) => {
            console.error('Error:', error.message);
          });
      } catch (e) {
        debugger;
      }

      try {
        if (base64Image == null) {
          imageToBase64(imageUrl + '.JPG', newWidth, newHeight)
            .then((resizedBase64) => {
              debugger;
              console.log('Resized image as Base64:', resizedBase64);
              debugger;
              setBase64Img(resizedBase64);
            })
            .catch((error) => {
              console.error('Error:', error.message);
            });
        }

      } catch (e) {
        debugger;
      }
    }
  }, [imagesrc]);

  const imageToBase64 = (imageUrl, newWidth, newHeight) => {
    return new Promise((resolve, reject) => {
      fetch(imageUrl)
        .then((response) => {
          if (!response.ok) {
            throw new Error(`Failed to fetch the image. Status code: ${response.status}`);
          }
          debugger;
          return response.blob();
        })
        .then((blob) => {
          const reader = new FileReader();
          reader.onloadend = () => {
            const base64Image = reader.result;
            resizeImage(base64Image, newWidth, newHeight)
              .then((resizedBase64) => resolve(resizedBase64))
              .catch((error) => reject(error));
          };
          reader.onerror = (error) => reject(new Error('Error reading the image: ' + error.message));
          reader.readAsDataURL(blob);
        })
        .catch((error) => {
          console.error('Error:', error);
          reject(new Error('Error fetching the image.'));
        });
    });
  };

  const resizeImage = (base64Image, newWidth, newHeight) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = base64Image;
      img.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        canvas.width = newWidth;
        canvas.height = newHeight;

        ctx.drawImage(img, 0, 0, newWidth, newHeight);

        const resizedBase64 = canvas.toDataURL('image/jpeg');

        resolve(resizedBase64);
      };

      img.onerror = (error) => reject(new Error('Error loading the image: ' + error.message));
    });
  };

  const generateSvg = (imageUri) => {
    return `
      <svg height="390" width="390" xmlns="http://www.w3.org/2000/svg">
        <defs>
          <clipPath id="circleClip">
            <circle cx="180" cy="180" r="170" fill="green" />
          </clipPath>
        </defs>
        <image href="${imageUri}" style="clip-path: url(#circleClip);" preserveAspectRatio="xMidYMid meet" />
      </svg>
    `;
  };

  return (
    <>
      <img
        src={`data:image/svg+xml;utf8,${encodeURIComponent(generateSvg(base64Image))}`}
        alt="Circle Image" style={{ position: "relative", left: "-389px", top: "17px", width: "415px" }}
      />
    </>
  );
};

export default CircleImageCurve;
