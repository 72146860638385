import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Loader from "../components/loader";
import { faHome, faInfo } from "@fortawesome/free-solid-svg-icons";
import {
  Col,
  Row,
  Card,
  Form,
  Button,
  Breadcrumb,
} from "@themesberg/react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import history from "../components/history";
import {
  addUpdateTree,
  uploadTreeImage,
} from "../Commanservice/commanservice.dev";
import config from "../config/axios/config.json";
const Mode = config.Application.mode;
const APIURL = config["CommonConstants"].web[Mode];

export class AddTree extends Component {
  constructor(props) {
    super(props);
    this.state = {
      TreeList: [],
      Loading: false,
      TreeName: "",
      BotanicalName: "",
      TamilName: "",
      NameInTamil: "",
      TreeDescription: "",
      TreeNo: "",
      errors: {
        TreeName: "",
        BotanicalName: "",
        TreeDescription: "",
        TamilName: "",
        NameInTamil: "",
        TreeNo: "",
      },
      isEdit: false,
      editTreeID: "",
      treeImage: "",
      ImageData: "",
      ImageName: "",
      editData: {}
    };
  }

  componentDidMount() {
    let data = JSON.parse(sessionStorage.getItem("editTreeData"));
    if (data != null) {
      this.setState({
        isEdit: sessionStorage.getItem("isTreeEdit"),
        TreeName: data.TreeName,
        BotanicalName: data.BotanicalName,
        TamilName: data.TamilName,
        NameInTamil: data.NameInTamil,
        TreeDescription: data.TreeDescription,
        TreeNo: data.TreeNo,
        editTreeID: data.ID,
        ImageName: data.ImageName,
      });
    }
    this.setState({ editData: data })
    sessionStorage.removeItem("editTreeData");
    sessionStorage.removeItem("isTreeEdit");
  }
  updateTreeDetails = (e, type) => {
    let updateData = this.state;
    if (e.target.value != "") {
      updateData["errors"][type] = ""
      updateData[type] = e.target.value;
      this.setState(updateData);
    } else {
      updateData["errors"][type] = `${type} should not be empty`
      e.target.value = ""
      updateData[type] = e.target.value;
      this.setState(updateData)
    }

  };
  AddTreeDetails = () => {
    let updateData = this.state;
    let isValidData = true,
      treeData = {};
    //TreeName
    if (updateData.TreeName != "") {
      if (updateData.TreeName.length > 40) {
        isValidData = false;
        updateData.errors["TreeName"] =
          "Tree Name should be less than 41 characters";
      } else {
        updateData.errors["TreeName"] = "";
        treeData.TreeName = updateData.TreeName;
      }
    } else {
      isValidData = false;
      updateData.errors["TreeName"] = "Tree Name should not be empty"
    }
    //BotanicalName
    if (updateData.BotanicalName != "") {
      if (updateData.BotanicalName.length > 40) {
        isValidData = false;
        updateData.errors["BotanicalName"] =
          "Botanical Name should be less than 41 characters";
      } else {
        updateData.errors["BotanicalName"] = "";
        treeData.BotanicalName = updateData.BotanicalName;
      }
    }
    else {
      isValidData = false;
      updateData.errors["BotanicalName"] = "Botanical Name should not be empty";
    }
    // TamilName
    if (updateData.TamilName != "") {
      if (updateData.TamilName.length > 40) {
        isValidData = false;
        updateData.errors["TamilName"] =
          "Tamil Name should be less than 41 characters";
      } else {
        updateData.errors["TamilName"] = "";
        treeData.TamilName = updateData.TamilName;
      }
    } else {
      isValidData = false;
      updateData.errors["TamilName"] = "Tamil Name should not be empty";
    }
    //NameInTamil
    if (updateData.NameInTamil != "") {
      if (updateData.NameInTamil.length > 40) {
        isValidData = false;
        updateData.errors["NameInTamil"] =
          "Name In Tamil should be less than 41 characters";
      } else {
        updateData.errors["NameInTamil"] = "";
        treeData.NameInTamil = updateData.NameInTamil;
      }
    } else {
      isValidData = false;
      updateData.errors["NameInTamil"] = "Name In Tamil should not be empty";
    }
    //TreeDescription
    if (updateData.TreeDescription != "") {
      if (updateData.TreeDescription.length > 600) {
        isValidData = false;
        updateData.errors["TreeDescription"] =
          "Tree Description should be less than 601 characters";
      } else {
        updateData.errors["TreeDescription"] = "";
        treeData.TreeDescription = updateData.TreeDescription;
      }
    } else {
      isValidData = false;
      updateData.errors["TreeDescription"] = "Tree Description should not be empty";
      // toast.error("TreeDescription must not be empty");
    }
    //TreeNo
    // if (updateData.TreeNo != "") {
    //   if (updateData.TreeNo.length > 50) {
    //     isValidData = false;
    //     updateData.errors["TreeNo"] =
    //       "TreeNo should be less than 51 characters";
    //   } else {
    //     updateData.errors["TreeNo"] = "";
    //     treeData.TreeNo = updateData.TreeNo;
    //   }
    // } else {
    //     isValidData = false;
    //     updateData.errors["TreeNo"] = "TreeNo must not be empty";
    //     //toast.error("TreeNo must not be empty");
    // }
    if (!this.state.isEdit) {
      treeData.ID = 0;
    } else {
      treeData.ID = this.state.editTreeID;
    }
    let isUploaded = true;
    if (!isValidData) {
      this.setState(updateData);
    } else {
      if (Object.keys(treeData).length == 6) {
        this.setState({ Loading: true });
        if (this.state.ImageData != "") {
          uploadTreeImage(
            `${APIURL.API_URL}/QRCode/uploadTreeImage`,
            this.state.ImageData
          )
            .then((response) => {
              this.setState({ ImageData: "" })
              console.log(response.message);
            })
            .catch((err) => {
              isUploaded = false;
              console.log("error in Upload", err);
            });
        }
        debugger;
        if (isUploaded) {
          treeData.imgData = this.state.ImageName;
          addUpdateTree(`${APIURL.API_URL}/QRCode/addTree`, treeData)
            .then((res) => {
              if (res.isSuccess) {
                this.setState({ Loading: false });
                this.setState({
                  TreeList: [],
                  Loading: false,
                  TreeName: "",
                  BotanicalName: "",
                  TamilName: "",
                  NameInTamil: "",
                  TreeDescription: "",
                  TreeNo: "",
                  errors: {
                    TreeName: "",
                    BotanicalName: "",
                    TamilName: "",
                    NameInTamil: "",
                    TreeDescription: "",
                    TreeNo: "",
                  },
                  isEdit: false,
                  editTreeID: "",
                  treeImage: "",
                  ImageData: "",
                  ImageName: "",
                });
                toast.success(res.message);
              } else {
                this.setState({
                  TreeList: [],
                  Loading: false,
                  TreeName: "",
                  BotanicalName: "",
                  TamilName: "",
                  NameInTamil: "",
                  TreeDescription: "",
                  TreeNo: "",
                  errors: {
                    TreeName: "",
                    BotanicalName: "",
                    TamilName: "",
                    NameInTamil: "",
                    TreeDescription: "",
                    TreeNo: "",
                  },
                  isEdit: false,
                  editTreeID: "",
                  treeImage: "",
                  ImageData: "",
                  ImageName: "",
                });
                toast.warning(res.message);
              }
            })
            .catch((err) => {
              console.log("error", err);
            });
        }
      }
    }
  };

  cancelDetails = () => {
    let { editData } = this.state
    if (!this.state.isEdit) {
      this.setState({
        TreeList: [],
        Loading: false,
        TreeName: "",
        BotanicalName: "",
        TamilName: "",
        NameInTamil: "",
        TreeDescription: "",
        TreeNo: "",
        errors: {
          TreeName: "",
          BotanicalName: "",
          TamilName: "",
          NameInTamil: "",
          TreeDescription: "",
          TreeNo: "",
        },
        isEdit: false,
        editTreeID: "",
        treeImage: "",
        ImageData: "",
        ImageName: "",
      })
    }
    else {
      this.setState({
        TreeName: editData.TreeName,
        BotanicalName: editData.BotanicalName,
        TamilName: editData.TamilName,
        NameInTamil: editData.NameInTamil,
        TreeDescription: editData.TreeDescription,
        TreeNo: editData.TreeNo,
        editTreeID: editData.ID,
        ImageName: editData.ImageName,
      });
    }
  }
  render() {
    const {
      TreeName,
      BotanicalName,
      TamilName,
      NameInTamil,
      TreeDescription,
      TreeNo,
      errors,
      ImageName,
    } = this.state;
    return (
      <>
        <Loader loader={this.state.Loading}></Loader>
        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-4">
          <div className="d-block mb-4 mb-md-0">
            <Breadcrumb
              className="d-none d-md-inline-block"
              listProps={{
                className: "breadcrumb-dark breadcrumb-transparent",
              }}
            >
              <Breadcrumb.Item
                onClick={() => (window.location.href = "/dashboard/overview")}
              >
                <FontAwesomeIcon icon={faHome} /> Home
              </Breadcrumb.Item>
              <Breadcrumb.Item
                onClick={() => (window.location.href = "/treeDetails")}
              >
                Tree Details
              </Breadcrumb.Item>
              <Breadcrumb.Item active>New Tree Details</Breadcrumb.Item>
            </Breadcrumb>
            <h4>New Tree Details</h4>
            {/* <p className="mb-0">Your web analytics dashboard template.</p> */}
          </div>
        </div>

        <Card border="light" className="bg-white shadow-sm mb-4">
          <Card.Body>
            <h5 className="mb-4">
              <b>Add new tree </b>
            </h5>
            <Form>
              <Row className="align-items-center">
                <Col md={6} className="mb-3">
                  <Form.Group id="TreeName">
                    <span style={{ color: "red" }}>*</span>
                    <Form.Label>Tree Name</Form.Label>
                    <Form.Control
                      required
                      type="text"
                      value={TreeName}
                      onChange={(e) => this.updateTreeDetails(e, "TreeName")}
                      placeholder="Enter Tree name"
                    />
                    {errors.TreeName != "" ? (
                      <label style={{ margin: "0px", color: "red" }}>
                        <FontAwesomeIcon icon={faInfo} className="me-2" />{" "}
                        {errors.TreeName}
                      </label>
                    ) : null}
                  </Form.Group>
                </Col>
                <Col md={6} className="mb-3">
                  <Form.Group id="BotanicalName">
                    <span style={{ color: "red" }}>*</span>
                    <Form.Label>Botanical Name</Form.Label>
                    <Form.Control
                      required
                      type="text"
                      value={BotanicalName}
                      onChange={(e) =>
                        this.updateTreeDetails(e, "BotanicalName")
                      }
                      placeholder="Enter Botanical Name"
                    />
                    {errors.BotanicalName != "" ? (
                      <label style={{ margin: "0px", color: "red" }}>
                        <FontAwesomeIcon icon={faInfo} className="me-2" />{" "}
                        {errors.BotanicalName}
                      </label>
                    ) : null}
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md={6} className="mb-3">
                  <Form.Group id="TamilName">
                    <span style={{ color: "red" }}>*</span>
                    <Form.Label>Tamil Name</Form.Label>
                    <Form.Control
                      type="text"
                      value={TamilName}
                      onChange={(e) => this.updateTreeDetails(e, "TamilName")}
                      placeholder="Enter Tamil Name"
                    />
                    {errors.TamilName != "" ? (
                      <label style={{ margin: "0px", color: "red" }}>
                        <FontAwesomeIcon icon={faInfo} className="me-2" />{" "}
                        {errors.TamilName}
                      </label>
                    ) : null}
                  </Form.Group>
                </Col>
                <Col md={6} className="mb-3">
                  <Form.Group id="NameInTamil">
                    <span style={{ color: "red" }}>*</span>
                    <Form.Label>Name In Tamil</Form.Label>
                    <Form.Control
                      type="text"
                      value={NameInTamil}
                      onChange={(e) => this.updateTreeDetails(e, "NameInTamil")}
                      placeholder="Enter Name In Tamil"
                    />
                    {errors.NameInTamil != "" ? (
                      <label style={{ margin: "0px", color: "red" }}>
                        <FontAwesomeIcon icon={faInfo} className="me-2" />{" "}
                        {errors.NameInTamil}
                      </label>
                    ) : null}
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                {/* <Col md={6} className="mb-3">
                  <Form.Group id="TreeNo">
                    <span style={{ color: "red" }}>*</span>
                    <Form.Label>Tree No.</Form.Label>
                    <Form.Control
                      className="TreeDesc"
                      required
                      type="text"
                      value={TreeNo}
                      onChange={(e) => this.updateTreeDetails(e, "TreeNo")}
                      placeholder="Enter Tree No"
                    />
                    {errors.TreeNo != "" ? (
                      <label style={{ margin: "0px", color: "red" }}>
                        <FontAwesomeIcon icon={faInfo} className="me-2" />{" "}
                        {errors.TreeNo}
                      </label>
                    ) : null}
                  </Form.Group>
                </Col> */}
                <Col md={6} className="mb-3">
                  <Form.Group id="TreeDescription">
                    <span style={{ color: "red" }}>*</span>
                    <Form.Label>Tree Description</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      required
                      type="text"
                      value={TreeDescription}
                      onChange={(e) =>
                        this.updateTreeDetails(e, "TreeDescription")
                      }
                      placeholder="Enter Tree Description"
                    />
                    {errors.TreeDescription != "" ? (
                      <label style={{ margin: "0px", color: "red" }}>
                        <FontAwesomeIcon icon={faInfo} className="me-2" />{" "}
                        {errors.TreeDescription}
                      </label>
                    ) : null}
                  </Form.Group>
                </Col>
              </Row>
              {/* <Row>
              </Row> */}
              <div style={{ display: 'flex' }}>
                <div className="mt-3" style={{ textAlign: "center" }}>
                  <Button
                    variant="primary"
                    onClick={() => {
                      this.AddTreeDetails();
                    }}
                  >
                    {this.state.isEdit ? `Update` : `Submit`}
                  </Button>
                </div>
                <div className="mt-3" style={{ marginLeft: '2rem' }}>
                  <Button variant="primary" onClick={(e) =>
                    this.cancelDetails()
                  } >
                    Cancel
            </Button>
                </div>
              </div>
            </Form>
          </Card.Body>
          <ToastContainer
            autoClose={3000}
            hideProgressBar={true}
            limit={0}
            draggable={false}
            position={toast.POSITION.TOP_RIGHT}
          ></ToastContainer>
        </Card>
      </>
    );
  }
}

export default AddTree;
