import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { BrowserRouter, Route, Link } from "react-router-dom";
import {
  faAngleLeft,
  faEnvelope,
  faUnlockAlt,
} from "@fortawesome/free-solid-svg-icons";
import {
  faFacebookF,
  faGithub,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import {
  Col,
  Row,
  Form,
  Card,
  Button,
  FormCheck,
  Container,
  InputGroup,
} from "@themesberg/react-bootstrap";

import { Routes } from "../../routes";
import BgImage from "../../assets/img/illustrations/signin.svg";
import { Loginauthentication } from "../../Commanservice/commanservice.dev";
import { ToastContainer,toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import config from "../../config/axios/config.json";
import HomePage from "../HomePage";
import history from "../../components/history";
const Mode = config.Application.mode;
const APIURL = config["CommonConstants"].web[Mode];

export default class Signin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
    };
  }

  signin = () => {
    // history.push ('/#/dashboard/overview')
    Loginauthentication(
      `${APIURL.API_URL}/QRCode/auth`,
      this.state.email,
      this.state.password
    )
      .then((response) => {
        console.log("process",response)
        if (response.isSuccess) {
          toast.success("Login Success")
          sessionStorage.setItem("User",JSON.stringify(response.data))
          window.location.href= "/dashboard/overview"
        } else {
          toast.error("Please enter valid Email id and Password")
        }
      })
      .catch((err) => {
        toast.error("Login Failed")
      });
  };
  handleemail(event) {
    this.setState({ email: event.target.value });
  }
  handlepassword(event) {
    this.setState({ password: event.target.value });
  }
  render() {
    return (
      <main>
        <section className="d-flex align-items-center my-5 mt-lg-6 mb-lg-5">
          <Container>
            <Row
              className="justify-content-center form-bg-image"
              style={{ backgroundImage: `url(${BgImage})` }}
            >
              <Col
                xs={12}
                className="d-flex align-items-center justify-content-center"
              >
                <div className="bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                  <div className="text-center text-md-center mb-4 mt-md-0">
                    <img style={{marginBottom:"28px"}} src = {"https://integranxt.com/wp-content/uploads/2020/09/integra-logo_16072018.png"}></img><br></br>
                    <h3 className="mb-0">Sign in to our platform</h3>
                  </div>
                  <Form className="mt-4">
                    <Form.Group id="email" className="mb-4">
                      <Form.Label>Your Email</Form.Label>
                      <InputGroup>
                        <InputGroup.Text>
                          <FontAwesomeIcon icon={faEnvelope} />
                        </InputGroup.Text>
                        <Form.Control
                          autoFocus
                          required
                          type="email"
                          onChange={this.handleemail.bind(this)}
                          placeholder="example@company.com"
                        />
                      </InputGroup>
                    </Form.Group>
                    <Form.Group>
                      <Form.Group id="password" className="mb-4">
                        <Form.Label>Your Password</Form.Label>
                        <InputGroup>
                          <InputGroup.Text>
                            <FontAwesomeIcon icon={faUnlockAlt} />
                          </InputGroup.Text>
                          <Form.Control
                            required
                            type="password"
                            onChange={this.handlepassword.bind(this)}
                            placeholder="Password"
                          />
                        </InputGroup>
                      </Form.Group>
                      <div className="d-flex justify-content-between align-items-center mb-4">
                        {/* <Card.Link className="small text-end">Lost password?</Card.Link> */}
                      </div>
                    </Form.Group>
                    <Button
                      variant="primary"
                      className="w-100"
                      onClick={() => this.signin()}
                    >
                      Sign in
                    </Button>
                  </Form>

                  {/* <div className="mt-3 mb-4 text-center">
                  <span className="fw-normal">or login with</span>
                </div>
                <div className="d-flex justify-content-center my-4">
                  <Button variant="outline-light" className="btn-icon-only btn-pill text-facebook me-2">
                    <FontAwesomeIcon icon={faFacebookF} />
                  </Button>
                  <Button variant="outline-light" className="btn-icon-only btn-pill text-twitter me-2">
                    <FontAwesomeIcon icon={faTwitter} />
                  </Button>
                  <Button variant="outline-light" className="btn-icon-only btn-pil text-dark">
                    <FontAwesomeIcon icon={faGithub} />
                  </Button>
                </div> */}
                  {/* <div className="d-flex justify-content-center align-items-center mt-4">
                  <span className="fw-normal">
                    Not registered?
                    <Card.Link as={Link} to={Routes.Signup.path} className="fw-bold">
                      {` Create account `}
                    </Card.Link>
                  </span>
                </div> */}
                </div>
              </Col>
            </Row>
          </Container>
          <ToastContainer
              autoClose={3000}
              hideProgressBar={true}
              limit={0}
              draggable={false}
              position={toast.POSITION.TOP_RIGHT}
            ></ToastContainer>
        </section>
      </main>
    );
  }
}

