import React, { useEffect, useState } from 'react';
import { PDFExport } from '@progress/kendo-react-pdf';

const EllipseImageCurve = (props) => {
  const { imageSrc } = props;
  const [base64Image, setBase64Image] = useState(null);
  const pdfExportComponent = React.createRef();

  useEffect(() => {
    if (imageSrc) {
      const imageUrl = imageSrc;
      const newWidth = 400; // Replace with your desired width
      const newHeight = 700; // Replace with your desired height
      try{
        imageToBase64(imageUrl+'.jpg', newWidth, newHeight)
        .then((resizedBase64) => {
          console.log('Resized image as Base64:', resizedBase64);
          setBase64Image(resizedBase64)
        })
        .catch((error) => {
          console.error('Error:', error.message);
        });
      }catch (e) {
        debugger;
      }

      try{
        if(base64Image == null)
        imageToBase64(imageUrl+'.JPG', newWidth, newHeight)
        .then((resizedBase64) => {
          console.log('Resized image as Base64:', resizedBase64);
          setBase64Image(resizedBase64)
        })
        .catch((error) => {
          console.error('Error:', error.message);
        });
      }catch (e) {
        debugger;
      }
      
    }
  }, [imageSrc]);
  const imageToBase64 = (imageUrl, newWidth, newHeight) => {
    return new Promise((resolve, reject) => {
      fetch(imageUrl)
        .then((response) => {
          if (!response.ok) {
            throw new Error(`Failed to fetch the image. Status code: ${response.status}`);
          }
          return response.blob();
        })
        .then((blob) => {
          const reader = new FileReader();
          reader.onloadend = () => {
            const base64Image = reader.result;
            resizeImage(base64Image, newWidth, newHeight)
              .then((resizedBase64) => resolve(resizedBase64))
              .catch((error) => reject(error));
          };
          reader.onerror = (error) => reject(new Error('Error reading the image: ' + error.message));
          reader.readAsDataURL(blob);
        })
        .catch((error) => {
          console.error('Error:', error);
          reject(new Error('Error fetching the image.'));
        });
    });
  };
  
  const resizeImage = (base64Image, newWidth, newHeight) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = base64Image;
      img.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        canvas.width = newWidth;
        canvas.height = newHeight;

        ctx.drawImage(img, 0, 0, newWidth, newHeight);
        
        const resizedBase64 = canvas.toDataURL('image/jpeg');
  
        resolve(resizedBase64);
      };
  
      img.onerror = (error) => reject(new Error('Error loading the image: ' + error.message));
    });
  };

  const generateSvg = (imageUri) => {
    return `
      <svg height="700px" width="400" xmlns="http://www.w3.org/2000/svg">
        <defs>
          <clipPath id="ellipseClip-1">
            <ellipse cx="89%" cy="66%" rx="85%" ry="75%" />
          </clipPath>
        </defs>
        <image href="${imageUri}" style="clip-path: url(#ellipseClip-1);" preserveAspectRatio="xMidYMid meet" />
      </svg>
    `;
  };

  const exportPDF = () => {
    if (pdfExportComponent.current && pdfExportComponent.current.save) {
      pdfExportComponent.current.save();
    } else {
      console.error('PDFExport component or save method not available');
    }
  };

  return (
    <>
      {/* <PDFExport ref={pdfExportComponent} paperSize="A4"> */}
      <img src={`data:image/svg+xml;utf8,${encodeURIComponent(generateSvg(base64Image))}`} alt="Ellipse Image"  style = {{position : "absolute", right : "0", height: "739px", width: "515px"}}/>
      {/* </PDFExport> */}
      {/* <button onClick={exportPDF}>Export to PDF</button> */}
    </>
  );
};

export default EllipseImageCurve;
