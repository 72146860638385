import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt, faDownload, faCheckCircle, faRocket, faHome, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { Col, Row, Button,Breadcrumb,ButtonGroup } from '@themesberg/react-bootstrap';
import { Table } from '@themesberg/react-bootstrap';
import { NewUserForm } from "../components/Forms";
import history from "../components/history";

export default () => {
    return (
        <>
            <Row className="mt-lg-5 mt-4 d-flex justify-content-center">
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
            <Breadcrumb.Item onClick={() => window.location.href = '/dashboard/overview'}> <FontAwesomeIcon icon={faHome} />  Home</Breadcrumb.Item>
            {/* <Breadcrumb.Item >Home</Breadcrumb.Item> */}
            <Breadcrumb.Item onClick={() => window.location.href = "/userDetails"}>Client Details</Breadcrumb.Item>
            <Breadcrumb.Item active>New Client Details</Breadcrumb.Item>
          </Breadcrumb>
          <h4>New Client Details</h4>
          {/* <p className="mb-0">Your web analytics dashboard template.</p> */}
        </div>
        {/* <div className="btn-toolbar mb-2 mb-md-0">
          <ButtonGroup>
            <Button variant="outline-primary" size="sm" onClick={() => history.push ('/#/AddNewUsers')}>Add</Button>
            <Button variant="outline-primary" size="sm">Upload File</Button>
          </ButtonGroup>
        </div> */}
      </div>
                <Col xl={11}>  
                    <NewUserForm />
  
                </Col>
            </Row>
        </>
    );
};
