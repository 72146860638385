import { useState } from "react";
import React, { Component, useCallback } from 'react';
import Dropzone from 'react-dropzone';
import { Col, Row, Card, Form, Button, InputGroup, Container, Breadcrumb, ProgressBar } from '@themesberg/react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt, faDownload, faCheckCircle, faRocket, faHome, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { UploadImage } from "../Commanservice/commanservice.dev";
import { ToastContainer, toast } from "react-toastify";
import config from "../config/axios/config.json";
const Mode = config.Application.mode;
const APIURL = config["CommonConstants"].web[Mode];
let FileName = []

const fileTypes = ["JPG", "PNG", "GIF"];

export default class Signin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Files: [],
      imagePreviewUrl: "",
      file: "",
      uploadedFile: '',
      percentage: 0
    };
  }
  upload = () => {
    FileName = []
    let fileLen = [], filecount = 0
    fileLen = this.state.Files.length
    if (this.state.Files.length > 0) {
      this.state.Files.map((ImageFile, y) => {
        // filecount = filecount + 1
        setTimeout(() => {
          UploadImage(
            `${APIURL.API_URL}/QRCode/upload`, ImageFile
          )
            .then((response) => {
              if (response.isSuccess) {
                filecount = filecount + 1
                this.setState({ uploadedFile: response.data, percentage: filecount / fileLen * 100 })
                if (this.state.percentage == 100) {
                  toast.success("Uploaded Successfully")
                }
              }
            })
            .catch((err) => {
              toast.error("error in Upload")
              console.log("error in Upload", err);
            });
        }, 2000)
      })
    }
    else {
      toast.error('Select atleast 1 file')
    }
    filecount = 0
  }
  handleUpload = (acceptedFiles) => {
    this.setState({ percentage: 0, Files: [] })
    FileName = []
    const { Files } = this.state
    let uploadedFiles = []
    acceptedFiles.map((a, i) => {
      FileName.push(a.name)
      let formdata = new FormData()
      formdata.append('file', acceptedFiles[i])
      formdata.append('filename', a.name)
      uploadedFiles.push(formdata)
      this.setState({ Files: uploadedFiles })
      console.log(this.state.Files)

    })
  }
  render() {
    return (
      <Container className="px-0">
        <h2 className="mb-4"></h2>
        <Row>
          <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
            <Breadcrumb.Item onClick={() => window.location.href = '/dashboard/overview'}> <FontAwesomeIcon icon={faHome} />  Home</Breadcrumb.Item>
            {/* <Breadcrumb.Item >Home</Breadcrumb.Item> */}
            {/* <Breadcrumb.Item onClick={() => window.location.href = "/userDetails"}>Client Details</Breadcrumb.Item> */}
            <Breadcrumb.Item active>Image Uploads</Breadcrumb.Item>
          </Breadcrumb>
          <Col xs={12} className="p-3 excelUploads">
            <Card>
              <Card.Body>
                <h1 className="bulkUploads">You Can Drag & Drop (or) Select Files</h1>
                <div className="DropFiles">
                  <Dropzone onDrop={acceptedFiles => this.handleUpload(acceptedFiles)}>
                    {({ getRootProps, getInputProps }) => (
                      <section style={{ height: "100px" }}>
                        <div style={{ height: "100px" }} className="Innertext" {...getRootProps()}>
                          <input style={{ height: "100px" }} className="Innertext" {...getInputProps()} />
                          <p style={{ height: "100px" }} className="Innertext">{this.state.Files.length == 0 ? "drop/select here" : `${FileName.join(",")}`}</p>
                        </div>
                      </section>
                    )}
                  </Dropzone>
                </div>
                <p style={{ color: "red", marginTop: "15px" }}>Note: File name should be CertificateNo.jpg (Long shot image) or CertificateNo_2.jpg (Close shot image) (eg:INTGG506_1) </p>
              </Card.Body>
            </Card>
            <div className="Upload"><button className="upbutton" onClick={() => this.upload()} >UPLOAD</button></div>
          </Col>
        </Row>
        {this.state.Files.length > 0 ?
          <div>
            <Row>
              <label style={{ textAlign: "center", marginBottom: "1rem" }}>{this.state.percentage == 100 ? 'Uploaded' : this.state.uploadedFile}</label>
            </Row>
            <Row>
              <ProgressBar now={this.state.percentage} /><label className='progressstatus'>{`${this.state.percentage}%`}</label>
            </Row>
          </div> : null}
        <ToastContainer
          autoClose={3000}
          hideProgressBar={true}
          limit={0}
          draggable={false}
          position={toast.POSITION.TOP_RIGHT}
        ></ToastContainer>
      </Container>
    )
  };
}


