
import React, { Component } from 'react';
import { faExternalLinkAlt, faDownload, faCheckCircle, faRocket, faHome, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { Col, Row, Nav, icon, Table, Card, Form, Button, ButtonGroup, Breadcrumb, InputGroup, ProgressBar, Pagination, Dropdown } from '@themesberg/react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import XLSX from 'xlsx';
import Loader from "../../components/loader";
import ClientTemplate from "../../Template/UserTemplate.xls";
import { uploadUser, getuser, addUpdateTree } from "../../Commanservice/commanservice.dev";
import { ToastContainer, toast } from "react-toastify";
import config from "../../config/axios/config.json";
const Mode = config.Application.mode;
const APIURL = config["CommonConstants"].web[Mode];
export class BootstrapTables extends Component {
  constructor(props) {
    super(props);
    this.state = {
      UserList: [],
      filteredUserList: [],
      isAnyDataHasError: false,
      TreeList: [],
      Loading: false,
      noofPages: 1,
      perPage: 250,
      activePage: 1,
      pageItems: [],
      filterList: [],
      newTreeList: [],
      defaultPage: [{
        pageNo: 'first',
        isActive: false
      }, {
        pageNo: 'prev',
        isActive: false
      }, {
        pageNo: 'next',
        isActive: false
      }, {
        pageNo: 'last',
        isActive: false
      }]
    }
    this.filePicked = this.filePicked.bind(this);
  };
  componentDidMount() {
    getuser(`${APIURL.API_URL}/QRCode/read`)
      .then((response) => {
        if (response.isSuccess) {
          this.setState({
            TreeList: response.data.tree,
            UserList: response.data.users
          })
        }
      })
      .catch((e) => {
        console.log("Error", e);
      });
  }
  setClientPagination = (TreeList, activePage) => {
    let { perPage } = this.state
    let pageNo = activePage;
    let filteredUserList = TreeList.slice(((pageNo - 1) * perPage), (((pageNo - 1) * perPage) + perPage))
    return filteredUserList;
  }
  setPageItem = (pageNo) => {
    let { pageItems, defaultPage } = this.state
    let pages = defaultPage;
    let tempItem = pageItems;
    tempItem.map(x => {
      if (x.pageNo == pageNo) {
        x.isActive = true
      }
      else {
        x.isActive = false
      }
    })
    pages.map(x => x.isActive = false)
    this.setState({
      activePage: parseInt(pageNo),
      filterList: this.setClientPagination(this.state.filteredUserList, pageNo),
      pageItems: tempItem,
      defaultPage: pages
    })
  }
  filePicked = (oEvent) => {
    let { filteredUserList, TreeList, UserList } = this.state;
    this.setState({ Loading: true })
    // Get The File From The Input
    var oFile = oEvent.target.files[0];
    if (oFile) {
      var sFilename = oFile.name.split('.');
      // Create A File Reader HTML5
      var reader = new FileReader();
      if (sFilename[sFilename.length - 1] == 'xls') {
        // Ready The Event For When A File Gets Selected
        reader.onload = (e) => {
          var data = e.target.result;
          var cfb = XLSX.CFB.read(data, { type: 'binary' });
          var wb = XLSX.read(data, { type: 'binary' });
          // Loop Over Each Sheet
          wb.SheetNames.forEach((sheetName) => {

            // Obtain The Current Row As CSV
            var sCSV = XLSX.utils.make_csv(wb.Sheets[sheetName]);
            var user = XLSX.utils.sheet_to_row_object_array(wb.Sheets[sheetName]);
            console.log("user", user)
            let userDetails = [], isAnyDataHasError = false;
            if (user.length > 0) {
              user.map((x, ind) => {
                let treeData = [];
                if (user[ind]['Tree Name']) {
                  treeData = TreeList.filter(x => x.TreeName.trim().toLowerCase() == user[ind]['Tree Name'].toString().trim().toLowerCase())
                }
                userDetails.push({
                  CertNo: user[ind]["Certificate No"] ? user[ind]["Certificate No"].toString() : '',
                  IntegraPoCUpdated: user[ind]['Integra PoC Updated'] ? user[ind]['Integra PoC Updated'].toString() : '',
                  KAMHead: user[ind]['KAM Head'] ? user[ind]['KAM Head'].toString() : '',
                  CustomerName: user[ind]['Customer Name'] ? user[ind]['Customer Name'].toString() : '',
                  EMail: user[ind]['E-Mail'] ? user[ind]['E-Mail'].toString() : '',
                  OriginationGroupName: user[ind]['Origination Group Name'] ? user[ind]['Origination Group Name'].toString() : '',
                  OriginalOrganizationName: user[ind]['Original Organization Name'] ? user[ind]['Original Organization Name'].toString() : '',
                  CurrentOrganization: user[ind]['Current Organization'] ? user[ind]['Current Organization'].toString() : '',
                  TreeName: user[ind]['Tree Name'] ? user[ind]['Tree Name'].toString() : '',
                  PlantedDuring: user[ind]['Planted during'] ? user[ind]['Planted during'].toString() : '',
                  FirstName: user[ind]['First Name'] ? user[ind]['First Name'].toString() : '',
                  TreeNo: user[ind]['Tree No'] ? user[ind]['Tree No'].toString() : '',
                  TreeID: treeData.length > 0 ? treeData[0].ID : '',
                  BotanicalName: user[ind]['Botanical Name'] ? user[ind]['Botanical Name'] : '',
                  TamilName: user[ind]['Tamil Name'] ? user[ind]['Tamil Name'] : '',
                  NameinTamil: user[ind]['Name in Tamil'] ? user[ind]['Name in Tamil'] : '',
                  TreeDescription: user[ind]['Tree Description'] ? user[ind]['Tree Description'] : '',
                })
              })
              let isDataValid = true;
              userDetails.map((row, ind) => {
                let error = []
                //cert no
                if (row.CertNo == '') {
                  isDataValid = false;
                  error.push({ propertyName: "CertNo", message: "Cert No is required" });
                }
                // else if (UserList.filter(x => x.CertNo == row.CertNo.toString().trim()).length > 0) {
                //   isDataValid = false;
                //   error.push({ propertyName: "CertNo", message: "Cert No already exist" });
                // }
                else if (row.CertNo.length > 50) {
                  isDataValid = false;
                  error.push({ propertyName: "CertNo", message: "Cert No should be less than 51 characters" });
                }
                //IntegraPoCUpdated
                if (row.IntegraPoCUpdated == '') {
                  isDataValid = false;
                  error.push({ propertyName: "IntegraPoCUpdated", message: "IntegraPoCUpdated is required" });
                }
                else if (row.IntegraPoCUpdated.length > 50) {
                  isDataValid = false;
                  error.push({ propertyName: "IntegraPoCUpdated", message: "IntegraPoCUpdated should be less than 51 characters" });
                }
                //KAMHead
                if (row.KAMHead == '') {
                  isDataValid = false;
                  error.push({ propertyName: "KAMHead", message: "KAMHead is required" });
                }
                else if (row.KAMHead.length > 50) {
                  isDataValid = false;
                  error.push({ propertyName: "KAMHead", message: "KAMHead should be less than 51 characters" });
                }
                //CustomerName
                // if (row.CustomerName == '') {
                //   isDataValid = false;
                //   error.push({ propertyName: "CustomerName", message: "CustomerName is required" });
                // }
                // else 
                if (row.CustomerName.length > 100) {
                  isDataValid = false;
                  error.push({ propertyName: "CustomerName", message: "CustomerName should be less than 101 characters" });
                }
                //EMail
                if (row.EMail == '') {
                  isDataValid = false;
                  error.push({ propertyName: "EMail", message: "EMail is required" });
                }
                else if (row.EMail.length > 50) {
                  isDataValid = false;
                  error.push({ propertyName: "EMail", message: "EMail should be less than 51 characters" });
                }
                //OriginationGroupName
                // if (row.OriginationGroupName == '') {
                //   isDataValid = false;
                //   error.push({ propertyName: "OriginationGroupName", message: "OriginationGroupName is required" });
                // }
                // else 
                if (row.OriginationGroupName.length > 100) {
                  isDataValid = false;
                  error.push({ propertyName: "OriginationGroupName", message: "OriginationGroupName should be less than 101 characters" });
                }
                //OriginalOrganizationName
                // if (row.OriginalOrganizationName == '') {
                //   isDataValid = false;
                //   error.push({ propertyName: "OriginalOrganizationName", message: "OriginalOrganizationName is required" });
                // }
                // else 
                if (row.OriginalOrganizationName.length > 100) {
                  isDataValid = false;
                  error.push({ propertyName: "OriginalOrganizationName", message: "OriginalOrganizationName should be less than 101 characters" });
                }
                //CurrentOrganization
                if (row.CurrentOrganization.length > 50) {
                  isDataValid = false;
                  error.push({ propertyName: "CurrentOrganization", message: "CurrentOrganization should be less than 51 characters" });
                }
                //TreeName
                if (row.TreeName == '') {
                  isDataValid = false;
                  error.push({ propertyName: "TreeName", message: "TreeName is required" });
                }
                else if (row.TreeID == '') {
                  let treeData = this.newTreeData(row)
                  if (treeData.err.length > 0) {
                    treeData.err.map(e => {
                      isDataValid = false;
                      error.push({ propertyName: e.propertyName, message: e.message });
                    })
                  }
                  else {
                    let existinExcel = [];//duplicate tree in Excel
                    existinExcel = this.state.newTreeList.filter(x => x.TreeName.trim().toLowerCase() == treeData.data.TreeName.trim().toLowerCase())
                    if (existinExcel.length == 0) {
                      this.setState({
                        newTreeList: [...this.state.newTreeList, treeData.data]
                      });
                    }
                  }
                }
                //PlantedDuring
                if (row.PlantedDuring == '') {
                  isDataValid = false;
                  error.push({ propertyName: "PlantedDuring", message: "PlantedDuring is required" });
                }
                else if (row.PlantedDuring.length > 50) {
                  isDataValid = false;
                  error.push({ propertyName: "PlantedDuring", message: "PlantedDuring should be less than 51 characters" });
                }
                //FirstName
                if (row.FirstName == '') {
                  isDataValid = false;
                  error.push({ propertyName: "FirstName", message: "FirstName is required" });
                }
                else if (row.FirstName.length > 50) {
                  isDataValid = false;
                  error.push({ propertyName: "FirstName", message: "FirstName should be less than 51 characters" });
                }
                if (!isDataValid)
                  isAnyDataHasError = true;
                // userDetails[ind].TreeNo = row.TreeNo ? row.TreeNo : ''
                userDetails[ind].Image1 = ''
                userDetails[ind].Image2 = ''
                userDetails[ind].error = error
                userDetails[ind].type = UserList.filter(x => x.CertNo == row.CertNo.toString().trim()).length > 0 ? 1 : 0
                userDetails[ind].Id =  UserList.filter(x => x.CertNo == row.CertNo.toString().trim()).length > 0 ?UserList.filter(x => x.CertNo == row.CertNo.toString().trim())[0].ID:''
              })
              // console.log(userDetails)
              let pageitems = [];
              this.setState({
                filteredUserList: userDetails,
                isAnyDataHasError: isAnyDataHasError,
                Loading: false,
                filterList: this.setClientPagination(userDetails, 1),
                noofPages: Math.ceil(userDetails.length / this.state.perPage)
              });
              for (let i = 1; i <= Math.ceil(userDetails.length / this.state.perPage); i++) {
                pageitems.push({
                  pageNo: i,
                  isActive: i == 1 ? true : false
                })
              }
              this.setState({ pageItems: pageitems })
              this.setPageItem(1)
            }
          });
        }
        // Tell JS To Start Reading The File.. You could delay this if desired
        reader.readAsBinaryString(oFile);
      }
      else {
        if (document.getElementById("userupload") != null) {
          document.getElementById("userupload").value = "";
        }
        this.setState({ filteredUserList: [], isAnyDataHasError: false, Loading: false });
        toast.error('Please upload a valid excel file')
      }
      oEvent.target.value = '';
    }
    else {
      // if (document.getElementById("userupload") != null) {
      //   document.getElementById("userupload").value = document.getElementById("userupload").value;
      // }
      this.setState({ filteredUserList: [], isAnyDataHasError: false, Loading: false })
    }
  }
  newTreeData = (tree) => {
    let errors = [], newTreeData = {};
    //TreeName
    if (tree.TreeName.length > 50) {
      errors.push({ propertyName: "TreeName", message: "Tree Name should be less than 51 characters" });
    }
    else {
      newTreeData.TreeName = tree.TreeName;
    }
    //BotanicalName
    if (tree.BotanicalName != '') {
      if (tree.BotanicalName.length > 50) {
        errors.push({ propertyName: "BotanicalName", message: "Botanical Name should be less than 51 characters" });
      }
      else {
        newTreeData.BotanicalName = tree.BotanicalName;
      }
    }
    else {
      errors.push({ propertyName: "BotanicalName", message: "Botanical Name is required" });
    }
    //TamilName
    if (tree.TamilName != '') {
      if (tree.TamilName.length > 50) {
        errors.push({ propertyName: "TamilName", message: "Tamil Name should be less than 51 characters" });
      }
      else {
        newTreeData.TamilName = tree.TamilName;
      }
    }
    else {
      errors.push({ propertyName: "TamilName", message: "Tamil Name is required" });
    }
    //NameInTamil
    if (tree.NameinTamil != '') {
      if (tree.NameinTamil.length > 50) {
        errors.push({ propertyName: "NameInTamil", message: "NameInTamil should be less than 51 characters" });
      }
      else {
        newTreeData.NameInTamil = tree.NameinTamil;
      }
    }
    else {
      errors.push({ propertyName: "NameinTamil", message: "NameInTamil is required" });
    }
    //TreeDescription
    if (tree.TreeDescription != '') {
      if (tree.TreeDescription.length > 2000) {
        errors.push({ propertyName: "TreeDescription", message: "TreeDescription should be less than 2001 characters" });
      }
      else {
        newTreeData.TreeDescription = tree.TreeDescription;
      }
    }
    else {
      errors.push({ propertyName: "NameinTamil", message: "TreeDescription is required" });
    }
    console.log(errors)
    newTreeData.ID = 0;
    return {
      err: errors,
      data: newTreeData
    };
  }
  imageHandler = (e) => {
    console.log("sat", e.target.value)
    var att = document.createAttribute("src");       // Create a "class" attribute
    att.value = e.target.value;
  }
  updateUserDetails = () => {
    let { filteredUserList } = this.state;
    this.setState({ Loading: true });
    if (!this.state.isAnyDataHasError && this.state.newTreeList.length > 0) {
      this.state.newTreeList.map(tree => {
        addUpdateTree(
          `${APIURL.API_URL}/QRCode/addTree`,
          tree
        )
          .then((response) => {
            this.setState({ newTreeList: [] });
            console.log(response.message);
          })
          .catch((err) => {
            console.log("error in Upload", err);
          });
      })
    }
    if (filteredUserList.length > 0) {
      this.setState({ Loading: true })
      uploadUser(
        `${APIURL.API_URL}/QRCode/uploadUser`, filteredUserList
      )
        .then((response) => {
          if (response.isSuccess) {
            this.setState({ filteredUserList: [], filterList: [], Loading: false })
            toast.success(response.message)
          }
        })
        .catch((err) => {
          console.log("error in Upload", err);
        });
    }
    else {
      toast.error("User Data should not be Empty")
    }
    this.setState({ Loading: false });
  }
  exportToCSV = () => {
    let { filteredUserList } = this.state;
    var data = filteredUserList
    let downloadData = [];
    data.map((x, ind) => {
      x.errorList = ''
      if (x.error.length > 0) {
        x.error.map(e => {
          x.errorList = x.errorList + `${e.propertyName}:${e.message}\n`
        })
      }
      downloadData.push({
        Sl_No: ind + 1,
        CertNo: x.CertNo,
        IntegraPoCUpdated: x.IntegraPoCUpdated,
        KAMHead: x.KAMHead,
        CustomerName: x.CustomerName,
        EMail: x.EMail,
        OriginationGroupName: x.OriginationGroupName,
        OriginalOrganizationName: x.OriginalOrganizationName,
        CurrentOrganization: x.CurrentOrganization,
        TreeName: x.TreeName,
        PlantedDuring: x.PlantedDuring,
        FirstName: x.FirstName,
        TreeNo: x.TreeNo,
        Error: x.errorList
      })
    })
    let ws = XLSX.utils.json_to_sheet(downloadData);
    let wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "sheet");
    let buf = XLSX.write(wb, { bookType: 'xls', type: 'buffer' });
    let str = XLSX.write(wb, { bookType: 'xls', type: 'binary' });
    XLSX.writeFile(wb, `ClientData.xls`);
  }
  defaultPagination = (pageType, activePage) => {
    let { pageItems, defaultPage } = this.state
    let tempItem = defaultPage, pageitems = pageItems;
    this.setState({ activePage: activePage, filterList: this.setClientPagination(this.state.filteredUserList, activePage) })
    pageitems.map(x => x.isActive = false)
    tempItem.map((x, ind) => {
      if (pageType == 'first') {
        if (ind == 0) {
          tempItem[0].isActive = true
        }
        else {
          tempItem[ind].isActive = false
        }
      }
      else if (pageType == 'prev') {
        if (ind == 1) {
          tempItem[1].isActive = true
        }
        else {
          tempItem[ind].isActive = false
        }
      }
      else if (pageType == 'next') {
        if (ind == 2) {
          tempItem[2].isActive = true
        }
        else {
          tempItem[ind].isActive = false
        }
      }
      else if (pageType == 'last') {
        if (ind == 3) {
          tempItem[3].isActive = true
        }
        else {
          tempItem[ind].isActive = false
        }
      }
    })

    this.setState({ defaultPage: tempItem, pageItems: pageitems })
  }
  render() {
    const { isAnyDataHasError, perPage, activePage } = this.state;
    return (
      <div className='excelUploads'>
        <Loader loader={this.state.Loading}></Loader>
        <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
          <Breadcrumb.Item onClick={() => window.location.href = '/dashboard/overview'}> <FontAwesomeIcon icon={faHome} />  Home</Breadcrumb.Item>
          {/* <Breadcrumb.Item >Home</Breadcrumb.Item> */}
          <Breadcrumb.Item active>Client Uploads</Breadcrumb.Item>
        </Breadcrumb>
        <Card border="light" className="bg-white shadow-sm mb-4">
          <Card.Body>
            <div style={{ display: "flex", justifyContent: "space-between" }}><h4>Upload Excel Files Here</h4> <span className="action-btn">
              <a href={ClientTemplate} style={{ float: "right", textDecoration: "underline" }} download="ClientUploadTemplate.xls">Download Template</a><br></br>
              {this.state.isAnyDataHasError ? <a onClick={() => { this.exportToCSV() }} style={{ float: "right", textDecoration: "underline" }} >Export to Excel with error</a> : null}
            </span></div>
            <div className="uploads">
              <input id='userupload' type="file" onChange={(evt) => { this.filePicked(evt); }} />
            </div>
          </Card.Body>
        </Card>

        <Card border="light" className="table-wrapper table-responsive shadow-sm">
          <Card.Body className="pt-0">
            <Table hover className="user-table align-items-center">
              <thead>
                <tr>
                  <th className="border-bottom">S.No </th>
                  <th className="border-bottom">Certificate No</th>
                  <th className="border-bottom">Integra PoC Updated</th>
                  <th className="border-bottom">KAM Head</th>
                  <th className="border-bottom">Customer Name</th>
                  <th className="border-bottom">Email</th>
                  <th className="border-bottom">Origination Group Name</th>
                  <th className="border-bottom">Original Organization Name</th>
                  <th className="border-bottom">Current Organization</th>
                  <th className="border-bottom">Tree Name</th>
                  <th className="border-bottom">Tree No</th>
                  <th className="border-bottom">Planted during</th>
                  <th className="border-bottom">Botanical Name</th>
                  <th className="border-bottom">Tamil Name</th>
                  <th className="border-bottom">Name in Tamil</th>
                  <th className="border-bottom">First Name</th>
                  <th className="border-bottom">Tree Description</th>
                  {isAnyDataHasError ? <th className="border-bottom">Error</th> : null}
                </tr>
              </thead>
              <tbody>
                {/* <tr> */}
                {this.state.filterList.map((t, index) => <tr key={`transaction-${t.invoiceNumber}`}>
                  <td>
                    {(this.state.activePage - 1) * this.state.perPage + index + 1}
                  </td>
                  <td>
                    {t.CertNo}
                  </td>
                  <td>
                    {t.IntegraPoCUpdated}
                  </td>
                  <td>
                    {t.KAMHead}
                  </td>
                  <td>
                    {t.CustomerName}
                  </td>
                  <td>
                    {t.EMail}
                  </td>
                  <td>
                    {t.OriginationGroupName}
                  </td>
                  <td>
                    {t.OriginalOrganizationName}
                  </td>
                  <td>
                    {t.CurrentOrganization}
                  </td>
                  <td>
                    {t.TreeName}
                  </td>
                  <td>
                    {t.TreeNo}
                  </td>
                  <td>
                    {t.PlantedDuring}
                  </td>
                  <td>
                    {t.BotanicalName}
                  </td>
                  <td>
                    {t.TamilName}
                  </td>
                  <td>
                    {t.NameinTamil}
                  </td>
                  <td>
                    {t.FirstName}
                  </td>
                  <td>
                    {t.TreeDescription}
                  </td>
                  {isAnyDataHasError ? <td>
                    {t.error.map((err, errIndex) => {
                      return (
                        <div key={errIndex}>
                          <p className="pe-error-cell ErrorLbel"><b>{err.propertyName}</b>: {err.message}</p>
                        </div>
                      )
                    })}
                  </td> : null}
                </tr>
                )}
              </tbody>
            </Table>
            {this.state.filteredUserList.length > 0 ?
              <Pagination>
                <Pagination.First onClick={(e) => this.defaultPagination('first', 1, e)} active={this.state.defaultPage[0].isActive} />
                <Pagination.Prev onClick={(e) => this.defaultPagination('prev', this.state.activePage - 1)} active={this.state.defaultPage[1].isActive} />
                {this.state.pageItems.map((p, index) => (
                  <Pagination.Item key={index} onClick={(e) => this.setPageItem(e.currentTarget.text ? e.currentTarget.text : 1)} active={p.isActive}>
                    {p.pageNo}
                  </Pagination.Item>
                ))}
                <Pagination.Next onClick={(e) => this.defaultPagination('next', this.state.activePage + 1)} active={this.state.defaultPage[2].isActive} />
                <Pagination.Last onClick={(e) => this.defaultPagination('last', this.state.noofPages)} active={this.state.defaultPage[3].isActive} />
              </Pagination> : null}
          </Card.Body>
        </Card>
        <p style={{ color: "red", marginTop: "15px" }}>Note :</p>
        <p style={{ color: "red", marginTop: "15px" }}>* if same client data/ Certificate number is present in more that row of the same excel upload file,</p>
        <p style={{ color: "red", marginTop: "13px",marginLeft:"11px" }}>detail from latest record will be overwritten.</p>
        <p style={{ color: "red", marginTop: "15px" }}>* if incremental or limited client data excel file is being uploaded,</p>
        <p style={{ color: "red", marginTop: "13px",marginLeft:"11px" }}>     new client data/ Certificate number will be inserted and existing client information will get updated(overwritten)</p>
        <div className="mt-34">
          <Button style={{ marginLeft: '41rem', marginTop: '2rem' }} variant="primary" disabled={this.state.isAnyDataHasError} onClick={() => { this.updateUserDetails() }}>Submit</Button>
        </div>
        <ToastContainer
          autoClose={3000}
          hideProgressBar={true}
          limit={0}
          draggable={false}
          position={toast.POSITION.TOP_RIGHT}
        ></ToastContainer>
      </div>
    );
  };
};
export default BootstrapTables;