import React, { Component } from "react";
import { savePDF } from "@progress/kendo-react-pdf";
import logo from "../../assets/img/logo.jpeg";
import md1 from "../../assets/img/ma1.PNG";
import md2 from "../../assets/img/ma2.PNG";
import co2 from "../../assets/img/Green Gift Certificate-03.png"
import drop from "../../assets/img/Green Gift Certificate-04.png"
import temp from "../../assets/img/Green Gift Certificate-05.png"
import config from "../../config/axios/config.json";
import shape1 from "../../assets/img/shape1.png"
import shape2 from "../../assets/img/shape2.png"
import lshape from "../../assets/img/L shape.png"
import EllipseImageCurve from "./EllipseImageCurve";
import CircleImageCurve from "./CircleImageCurve";
import { ToastContainer, toast } from "react-toastify";
import Loader from "../../components/loader";
var QRCode = require("qrcode.react");
// const Cryptr = require("cryptr");
// const cryptr = new Cryptr("myTotalySecretKey");
const Mode = config.Application.mode;
const APIURL = config["CommonConstants"].web[Mode];

//export default class Certificate extends Component
export default class Dynamiccerficate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      CurrentUser: {},
      Refrence: [],
      WillEncrypt: "",
      Singledownload: false,
      loader: false,
    };

    this.bodyRef = React.createRef();
  }
  
  Export = (index) => {
    let filteredUserList = this.props.userData;
    if (filteredUserList.length > index) {
      let value = filteredUserList[index];
      if (value.CheckedForPdf) {
        value.CheckedForPdf = false;
        this.savepdfbyasync(index, value.CustomerName).then((data) => {
          this.Export(index + 1);
        });
      } else {
        this.Export(index + 1);
      }
    } else {
      this.setState({ loader: false });
    }
  };
  createPdf = () => {
    if (document.getElementsByClassName("MainId")) {
      document.getElementsByClassName("MainId")[0].checked = false;
    }
    const htmlButtons = document.getElementsByClassName("Checking");
    const htmlButtonsArray = [...htmlButtons];
    htmlButtonsArray.map((button) => (button.checked = false));
    this.Export(0);
  };

// kendoreact
  savepdfbyasync = (index, CustomerName) => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        savePDF(
          this[`body${index}`].current,
          {
            paperSize: "A3",
            landscape: "true",
            fileName: CustomerName.split(".").join(" "),
          },
          () => {
            resolve("Success " + CustomerName);
          }
        );
      }, 15000); // 10 seconds
    });
  };
  

  render() {
    this.props.userData.map((a, b) => {
      this[`body${b}`] = React.createRef();
    });
    if (sessionStorage.getItem("pressed") != "") {
      sessionStorage.setItem("pressed", "");
      setTimeout(() => {
        this.createPdf();
        this.setState({ Singledownload: true, loader: true });
      }, 500);
    }
    return (
      <>
        <Loader loader={this.state.loader}></Loader>
        <section className="pdf-container">
          <section className="pdf-toolbar">
            <button
              className="Toolbutton"
              onClick={(e) => {
                if (this.props.userData.length > 0) {
                  this.setState({ loader: true });
                  this.createPdf();
                } else {
                  toast.warning("Select atleast 1 certificate to download");
                }
              }}
            >
              Download Selected Certificate
            </button>
          </section>
          <div style={{ overflow: "hidden", height: 0, width: 0 }}>
            {/* <div> */}
            {this.props.userData.map((value, index) => {
              return (
                <section className="pdf-body" ref={this[`body${index}`]} style={{ fontFamily: "LiberationSans" }}>
                <div className="Toexport" >
                  <div className="Certificate">
                    <div class="mainContainer" style={{marginBottom: "80px"}}>

                      <div class="containerInner">
                        <div class="leftContainer">
                          <img src={shape1} class="outerImg" />
                          <div class="logo">
                            <img src={logo} class="logoImg" alt="Italian Trulli" width={170} height={170} />
                          </div>
                          <div class="namePart">
                            <div class="User" style={{ letterSpacing: "2px" }}>{value.CertNo == "INTGG2417" ? "To" : "Dear"}</div>
                            <div
                              style={{
                                fontSize: `${value.CustomerName.length < 20
                                  ? "33px"
                                  : value.CustomerName.length >= 20 &&
                                    value.CustomerName.length < 30
                                    ? "25px"
                                    : value.CustomerName.length >= 30 &&
                                      value.CustomerName.length < 40
                                      ? "20px"
                                      : "20px"
                                  }`,
                                marginTop: `${value.CustomerName.length < 20
                                  ? "-15px"
                                  : value.CustomerName.length >= 20 &&
                                    value.CustomerName.length < 30
                                    ? "-10px"
                                    : value.CustomerName.length >= 30 &&
                                      value.CustomerName.length < 40
                                      ? "0px"
                                      : "10px"
                                  }`,
                              }}
                              class="userName"
                            >
                              {value.CustomerName}
                            </div>
                            <div
                              class="dots"
                              style={{
                                top: `${value.CustomerName.length < 20
                                  ? "-22px"
                                  : value.CustomerName.length >= 20 &&
                                    value.CustomerName.length < 30
                                    ? "-20px"
                                    : value.CustomerName.length >= 30 &&
                                      value.CustomerName.length < 40
                                      ? "-10px"
                                      : "-5px"
                                  }`,
                              }}
                            >
                              {".".repeat(15 * 7)}
                            </div>

                          </div>
                          <div class="centerContent">
                            <div class="wishes">Wishing you a prosperous 2024!</div>
                            <div class="content1 first">
                              We wish to express our sincere gratitude for your invaluable support. It
                              brings us immense joy to share that your tree, flourishing since  {value.PlantedDuring}, is now a proud member of Integra's Private Forestry Movement,
                              signifying our shared dedication to sustainable practices. Your partnership
                              holds a special place for us, and I look forward to nurturing this mutually
                              rewarding relationship throughout 2024 and beyond.
                            </div>
                          </div>

                          <div class="Affiliated" style={{ display: "flex" }}>
                            <div class="managingdirector">
                              <img class="mdrole" src={md1}></img><br></br>
                              <hr class="line" />
                              <span class="Role">
                                Founder, Managing Director & CEO
                              </span>
                            </div>
                            <div class="managingdirector1">
                              <img class="mdrole" src={md2}></img><br></br>
                              <hr class="line2" />
                              <span class="Role">Co-founder & JMD</span>
                            </div>
                            <img src={shape1} class="outerImg2" />
                          </div>
                          
                        </div>
                        <div class="rightContainer">
                        <img src={lshape} style={{position: "absolute", right: "-74px", top: "-83.18px"}}/>
                        <div className="initial">
                        
                          <svg height="400" width="400">
                            <circle cx="200" cy="200" r="195" style={{ fill: 'none', stroke: '#3ab54a', strokeWidth: 3 }} />
                            
                          </svg>
                          <CircleImageCurve imagesrc={`https://qrgreencode.blob.core.windows.net/qrcode/QRCode/${value.CertNo}_2`}/>

                          </div>
                          <img src={lshape} style={{position: "absolute", left: "-605.5px", bottom: "-83px", transform: "rotate(180deg)"}}/>
                        </div>
                        
                      </div>

                    </div>

                    {/* <!-- Second Page --> */}
                    <div class="mainContainer">
                    
                      <div class="containerInner" style={{ height: "742px"}}>
                      
                        <div class="leftContainer">
                        <img src={shape2} class="outerImg3" />
                          <div class="logo" style={{paddingTop:"0px"}}>
                            <img src={logo} class="logoImg" alt="Italian Trulli" width={150} height={100} style={{ marginLeft: "15px" }} />
                          </div>
                          <div class="first-con" style={{ color: "#0b9444", paddingBottom: "0.5em", paddingTop: "0.2em" }}>
                            A remarkable species, your tree boosts the environment
                            in significant ways.
                          </div>
                          <div class="comm_div">
                            <img src={co2} alt="co2" width={50} height={50} />
                            <div className="text" style={{ fontSize: "16px" }}>It actively absorbs {value.CarbonConsumption} pounds of carbon dioxide and
                              provides life-sustaining oxygen.</div>
                          </div>
                          <div class="comm_div">
                            <img src={drop} alt="droplet" width={50} height={50} />
                            <div className="text" style={{ fontSize: "16px" }}>Intercepting roughly   {value.Rainfall} gallons of rainfall annually,
                              it contributes significantly to water conservation efforts.</div>
                          </div>
                          <div class="comm_div">
                            <img src={temp} alt="co2" width={50} height={50} />
                            <div className="text" style={{ fontSize: "16px" }}>It effectively reduces the local temperature by {value.AtmosphericTemp} degree, creating a more pleasant atmosphere.</div>
                          </div>
                          {/* css */}
                          {/* <div className="QRPage" style={{ clipPath: 'ellipse(75% 100% at 50% 0)', position: "relative" }}>
                            <div className="contentInsideQRPage">
                              <p className="green-para">

                              </p>
                            </div>
                          </div> */}
                          {/* svg */}
                          <div class="QRPage">
                            <div class="QREllipse">
                              <svg viewBox="0 0.3 1 2" xmlns="http://www.w3.org/2000/svg" style={{ fill: "#0e9547" }}>
                              <ellipse cx="0.65" cy="0.45" rx="0.6" ry="0.3"></ellipse>
                              </svg>
                            </div>
                            <div className="contentInsideQRPage">
  <p
    className="green-para"
    style={{ fontSize: value.Treeinfo.TreeDescription.split(" ").length > 60 ? "14px" : "15px" }}
  >
    {
      value.Treeinfo.TreeDescription.split(" ").length > 82
        ? value.Treeinfo.TreeDescription.split(" ").slice(0, 82).join(" ") + "..."
        : value.Treeinfo.TreeDescription
    }
  </p>
</div>

                          </div>


                          <div class="QR">
                            <div class="margin">
                              <a href={`https://integraforest.com/UserPage/?id=${btoa(value.ID)}`}>
                                <QRCode
                                  value={`https://integraforest.com/UserPage/?id=${btoa(value.ID)}`}
                                  style={{
                                    width: "100px",
                                    height: "100px",
                                    position: "relative",
                                    padding: "7px",
                                    backgroundColor: "#fff",
                                  }}
                                ></QRCode>
                              </a>
                              <span class="QRcontent1">Experience your<br/>new digital certificate</span>
                            </div>
                          </div>




                        </div>
                        
                        <div className="rightContainer">
                          <EllipseImageCurve imageSrc={`https://qrgreencode.blob.core.windows.net/qrcode/QRCode/${value.CertNo}`} />
                        </div>
                      </div>
                    </div>


                  </div>
                  <ToastContainer
                    autoClose={3500}
                    hideProgressBar={true}
                    limit={0}
                    draggable={false}
                    position={toast.POSITION.TOP_RIGHT}
                  ></ToastContainer>
                </div>
              </section>
              );
            })}
          </div>
        </section>
      </>
    );
  }
}
