import React, { Component } from "react";
import { FileUploader } from "react-drag-drop-files";
// import {
//   Col,
//   Row,
//   Nav,
//   Table,
//   Card,
//   Form,
//   Button,
//   ButtonGroup,
//   Breadcrumb,
//   InputGroup,
//   ProgressBar,
//   Pagination,
//   Dropdown,
// } from "@themesberg/react-bootstrap";
import {
  Col,
  Row,
  Card,
  Form,
  Button,
  InputGroup,
  Container,
} from "@themesberg/react-bootstrap";
import img2 from "../../assets/img/leaves.png";
import "react-inner-image-zoom/lib/InnerImageZoom/styles.min.css";
import InnerImageZoom from "react-inner-image-zoom";
import config from "../../config/axios/config.json";
import { getuser } from "../../Commanservice/commanservice.dev";
import Loader from "../../components/loader";
const Mode = config.Application.mode;
const APIURL = config["CommonConstants"].web[Mode];
// const Cryptr = require("cryptr");

export default class Page extends Component {
  constructor(props) {
    super(props);
    this.state = {
      FilteredUserList: [],
      loader: true,
      Treeinfo: {},
      imgLink: ''
    };
  }

  componentDidMount() {
    let URl = window.location.href;
    const decryptedString = atob(URl.split("id=")[1]);
    
    getuser(`${APIURL.API_URL}/QRCode/read?id=`+decryptedString)
      .then((response) => {
        if (response.isSuccess) {
          this.setState({
            FilteredUserList: response.data.users[0],
            loader: false,
            Treeinfo: response.data.tree.filter(
              (a, b) =>
                a.ID ==
                response.data.users.filter((a, b) => a.ID == decryptedString)[0]
                  .TreeName
            )[0],
          });

          let imgURL = `https://qrgreencode.blob.core.windows.net/qrcode/QRCode/${response.data.users[0].CertNo}_2.JPG`
          this.setState({imgLink:imgURL})
        }
      })
      .catch((e) => {
        console.log("Error", e);
      });
  }
  render() {
    console.log("changes", this.state);
    return (
      <>
        <Loader loader={this.state.Loading}></Loader>
        <img style={{ display: 'none' }} onError={(e) => {  let imgURL = `https://qrgreencode.blob.core.windows.net/qrcode/QRCode/${this.state.FilteredUserList.CertNo}_2.jpg`
          this.setState({imgLink:imgURL})}}
          src={`https://qrgreencode.blob.core.windows.net/qrcode/QRCode/${this.state.FilteredUserList.CertNo}_2.JPG`}
        ></img>
        <div class="container1">
          <div class="img">
            <img
              class="image1"
              //https://qrgreencode.blob.core.windows.net/qrcode/QRCode/INTGG506_2.jpg
              src={`https://crossorigin.azurewebsites.net/proxy/?https://livewkldigitalbooks.blob.core.windows.net/titlestesting/QRCode/userbackground.jpg`}
              alt="flower Image"
            />
            <div class="container2">
              <div class="nameDetails">
                <div class="img1dear">{this.state.FilteredUserList.CertNo == "INTGG2417" ? "To":"Dear"}</div>
                <div class="img1name">
                  {this.state.FilteredUserList.CustomerName}
                </div>
                <div class="img1text">
                  Here’s the digital space of the tree we
  have planted on your behalf. You can visit
  this space any time of the day, all through
  the year
                </div>
              </div>
              <div class="container21">
                <div class="secondImage">
                  <div class="img2text">
                    <table class="imgTable">
                      <tr>
                        <td class="trLeft">Botanical Name</td>
                        <td class="colon">:</td>
                        <td class="trRight">{this.state.Treeinfo.BotanicalName}</td>
                      </tr>
                      <tr>
                        <td class="trLeft">Common Name</td>
                        <td class="colon">:</td>
                        <td class="trRight">
                          {this.state.Treeinfo.TreeName}
                        </td>
                      </tr>
                      <tr>
                        <td class="trLeft">Tree No.</td>
                        <td class="colon">:</td>
                        <td class="trRight">
                          {this.state.FilteredUserList.TreeNo}
                        </td>
                      </tr>
                      <tr>
                        <td class="trLeft">Planted during</td>
                        <td class="colon">:</td>
                        <td class="trRight">
                          {this.state.FilteredUserList.PlantedDuring}
                        </td>
                      </tr>
                    </table>
                    <div class="c21text">
                      {this.state.Treeinfo.TreeDescription}
                    </div>
                  </div>
                  <div className="ForText">
                    <div class="imagePart">
                      <InnerImageZoom
                        hasSpacer={true}
                        style={{ borderRadius: "25px", width: "100%", height: "auto" }}
                        // src={`https://crossorigin.azurewebsites.net/proxy/?https://livewkldigitalbooks.blob.core.windows.net/titlestesting/QRCode/${this.state.FilteredUserList.CertNo}_2.jpg`}
                        // zoomSrc={`https://crossorigin.azurewebsites.net/proxy/?https://livewkldigitalbooks.blob.core.windows.net/titlestesting/QRCode/${this.state.FilteredUserList.CertNo}_2.jpg`}
                        src={this.state.imgLink}
                        zoomSrc={this.state.imgLink}
                      />
                      {/* <img  src={`https://crossorigin.azurewebsites.net/proxy/?https://livewkldigitalbooks.blob.core.windows.net/titlestesting/QRCode/${this.state.FilteredUserList.CertNo}_2.jpg`} class="image2" alt="flower Image" /> */}
                    </div>
                    <div className="bottomzoom">
                      <p className="zoom">CLICK TO ZOOM</p>
                      {/* <button onClick={()=> window.open(`../../geotag?id=${window.location.href.split("id=")[1]}`, "_blank")} className="zoom">GEO TAGS</button> */}
                    </div>
                  </div>
                </div>
              </div>
              <div class="mapTop">
                <div class="normalText">
                  Integra's Private Forest is located here{" "}
                </div>
              </div>
              <div class="map-responsive">
              <img src="https://qrgreencode.blob.core.windows.net/qrcode/forest.PNG"/>
              </div>
             <br/>
             <br/>
             <br/>
             <br/>
             <br/>
             <br/>
             
              <div class="normalText">
              Integra's Farm is located here
                </div>
              <div class="map-responsive">
              <img src="https://qrgreencode.blob.core.windows.net/qrcode/forest_new.PNG"/>
              </div>
            </div>
          </div>
          <div class="thirdImage">
            <div class="GreenQuotes">
              <div className="LineB"></div>
              <div className="Changes">
                <img class="leaves" src={img2} /> <p></p>
                <div class="greenColor">Green </div>
                <div class="QuoteColor">Quotient!</div>
              </div>
              <div className="LineA"></div>
            </div>
            <div class="lowerContent">
              <div class="listBorder">
                <ul className="Ullist">
                  <li class="list">
                    <span className="Flex">
                      <svg fill="#3a9409" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24px" height="24px"><path d="M 12 2 C 6.4889971 2 2 6.4889971 2 12 C 2 17.511003 6.4889971 22 12 22 C 17.511003 22 22 17.511003 22 12 C 22 6.4889971 17.511003 2 12 2 z M 12 4 C 16.430123 4 20 7.5698774 20 12 C 20 16.430123 16.430123 20 12 20 C 7.5698774 20 4 16.430123 4 12 C 4 7.5698774 7.5698774 4 12 4 z M 11 7 L 11 11 L 7 11 L 7 13 L 11 13 L 11 17 L 13 17 L 13 13 L 17 13 L 17 11 L 13 11 L 13 7 L 11 7 z" /></svg>
                    </span>
                    <span className="Item">
                      Trees absorb the sun’s energy & account for 50% of energy
                      trapped by all organisms on Earth
                    </span>
                  </li>
                  <li class="list">
                    <span className="Flex">
                      <svg fill="#3a9409" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24px" height="24px"><path d="M 12 2 C 6.4889971 2 2 6.4889971 2 12 C 2 17.511003 6.4889971 22 12 22 C 17.511003 22 22 17.511003 22 12 C 22 6.4889971 17.511003 2 12 2 z M 12 4 C 16.430123 4 20 7.5698774 20 12 C 20 16.430123 16.430123 20 12 20 C 7.5698774 20 4 16.430123 4 12 C 4 7.5698774 7.5698774 4 12 4 z M 11 7 L 11 11 L 7 11 L 7 13 L 11 13 L 11 17 L 13 17 L 13 13 L 17 13 L 17 11 L 13 11 L 13 7 L 11 7 z" /></svg>
                    </span>
                    <span className="Item">
                      A single mature tree can absorb as much as 48 pounds of
                      carbon dioxide per year
                    </span>
                  </li>
                  <li class="list">
                    <span className="Flex">
                      <svg fill="#3a9409" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24px" height="24px"><path d="M 12 2 C 6.4889971 2 2 6.4889971 2 12 C 2 17.511003 6.4889971 22 12 22 C 17.511003 22 22 17.511003 22 12 C 22 6.4889971 17.511003 2 12 2 z M 12 4 C 16.430123 4 20 7.5698774 20 12 C 20 16.430123 16.430123 20 12 20 C 7.5698774 20 4 16.430123 4 12 C 4 7.5698774 7.5698774 4 12 4 z M 11 7 L 11 11 L 7 11 L 7 13 L 11 13 L 11 17 L 13 17 L 13 13 L 17 13 L 17 11 L 13 11 L 13 7 L 11 7 z" /></svg>
                    </span>
                    <span className="Item">
                      A tree releases enough oxygen back into the atmosphere to
                      support 2 human beings every year
                    </span>
                  </li>
                  <li class="list">
                    <span className="Flex">
                      <svg fill="#3a9409" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24px" height="24px"><path d="M 12 2 C 6.4889971 2 2 6.4889971 2 12 C 2 17.511003 6.4889971 22 12 22 C 17.511003 22 22 17.511003 22 12 C 22 6.4889971 17.511003 2 12 2 z M 12 4 C 16.430123 4 20 7.5698774 20 12 C 20 16.430123 16.430123 20 12 20 C 7.5698774 20 4 16.430123 4 12 C 4 7.5698774 7.5698774 4 12 4 z M 11 7 L 11 11 L 7 11 L 7 13 L 11 13 L 11 17 L 13 17 L 13 13 L 17 13 L 17 11 L 13 11 L 13 7 L 11 7 z" /></svg>
                    </span>
                    <span className="Item">
                      Trees sequester CO<sub>2</sub> during photosynthesis & act as carbon
                      sinks to negate the greenhouse effect
                    </span>
                  </li>
                  <li class="list">
                    <span className="Flex">
                      <svg fill="#3a9409" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24px" height="24px"><path d="M 12 2 C 6.4889971 2 2 6.4889971 2 12 C 2 17.511003 6.4889971 22 12 22 C 17.511003 22 22 17.511003 22 12 C 22 6.4889971 17.511003 2 12 2 z M 12 4 C 16.430123 4 20 7.5698774 20 12 C 20 16.430123 16.430123 20 12 20 C 7.5698774 20 4 16.430123 4 12 C 4 7.5698774 7.5698774 4 12 4 z M 11 7 L 11 11 L 7 11 L 7 13 L 11 13 L 11 17 L 13 17 L 13 13 L 17 13 L 17 11 L 13 11 L 13 7 L 11 7 z" /></svg>
                    </span>
                    <span className="Item">
                      Trees intercept water runoff, impede soil erosion & ensure
                      groundwater supply replenishment
                    </span>
                  </li>
                  <li class="list">
                    <span className="Flex">
                      <svg fill="#3a9409" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24px" height="24px"><path d="M 12 2 C 6.4889971 2 2 6.4889971 2 12 C 2 17.511003 6.4889971 22 12 22 C 17.511003 22 22 17.511003 22 12 C 22 6.4889971 17.511003 2 12 2 z M 12 4 C 16.430123 4 20 7.5698774 20 12 C 20 16.430123 16.430123 20 12 20 C 7.5698774 20 4 16.430123 4 12 C 4 7.5698774 7.5698774 4 12 4 z M 11 7 L 11 11 L 7 11 L 7 13 L 11 13 L 11 17 L 13 17 L 13 13 L 17 13 L 17 11 L 13 11 L 13 7 L 11 7 z" /></svg>
                    </span>
                    <span className="Item">
                      Trees renew the levels of soil organic matter & increase the soil's ability to absorb and hold water
                  </span>
                  </li>
                </ul>
              </div>
            </div>
            <div className="ForMobile">FOLLOW US ON</div>
            <div class="icons">
              <a href="https://www.facebook.com/IntegraSoftwareServices/">
                <svg width="50px" height="50px" viewBox="0 0 63 60" version="1.1" xmlns="http://www.w3.org/2000/svg">
                  <title>Artboard</title>
                  <desc>Created with Sketch.</desc>
                  <g id="Artboard" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <g id="Facebook" transform="translate(3.000000, 2.000000)">
                      <circle id="Oval" stroke="#A7C29D" stroke-width="3" fill="#FFFFFF" cx="28.5" cy="28.5" r="27"></circle>
                      <g id="216078_facebook_social_icon" transform="translate(18.000000, 13.000000)" fill="#3A9407" fill-rule="nonzero">
                        <path d="M12,12 L18,12 L18,18 L12,18 L12,32 L6,32 L6,18 L0,18 L0,12 L6,12 L6,9.49 C6,7.112 6.748,4.108 8.236,2.466 C9.724,0.82 11.582,0 13.808,0 L18,0 L18,6 L13.8,6 C12.804,6 12,6.804 12,7.798 L12,12 Z" id="Path"></path>
                      </g>
                    </g>
                  </g>
                </svg>
              </a>
              <a href="https://www.linkedin.com/company/integra-software-services-pvt-ltd">
                <svg width="50px" height="50px" viewBox="0 0 63 60" version="1.1" xmlns="http://www.w3.org/2000/svg" >
                  <title>Artboard</title>
                  <desc>Created with Sketch.</desc>
                  <g id="Artboard" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <g id="Linkedin" transform="translate(3.000000, 2.000000)">
                      <circle id="Oval" stroke="#A7C29D" stroke-width="3" fill="#FFFFFF" cx="28.5" cy="28.5" r="27"></circle>
                      <g id="8679367_linkedin_icon" transform="translate(11.000000, 11.000000)">
                        <polygon id="Path" points="0 0 36 0 36 35 0 35"></polygon>
                        <path d="M10.0860451,7.00133394 C10.0852044,8.6580961 8.72211584,10.0004957 7.04150122,9.99966766 C5.3608866,9.9988389 3.99916047,8.65509627 4.00000039,6.99833411 C4.00084108,5.34157195 5.36392962,3.99917238 7.04454424,4.00000038 C8.72515886,4.00082915 10.086885,5.34457178 10.0860451,7.00133394 L10.0860451,7.00133394 Z M10.1773353,12.2210438 L4.09129144,12.2210438 L4.09129144,31 L10.1773353,31 L10.1773353,12.2210438 Z M19.7932847,12.2210438 L13.737671,12.2210438 L13.737671,31 L19.7324243,31 L19.7324243,21.1455477 C19.7324243,15.6558529 26.9900316,15.1458812 26.9900316,21.1455477 L26.9900316,31 L33,31 L33,19.1056611 C33,9.85117553 22.2581325,10.1961564 19.7324243,14.7409037 L19.7932847,12.2210438 Z" id="Shape" fill="#3A9407" fill-rule="nonzero"></path>
                      </g>
                    </g>
                  </g>
                </svg>
              </a>
              <a href="https://twitter.com/Integra_Global">
                <svg width="50px" height="50px" viewBox="0 0 63 60" version="1.1" xmlns="http://www.w3.org/2000/svg" >
                  <title>Artboard</title>
                  <desc>Created with Sketch.</desc>
                  <g id="Artboard" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <g id="Twitter" transform="translate(3.000000, 2.000000)">
                      <circle id="Oval" stroke="#A7C29D" stroke-width="3" fill="#FFFFFF" cx="28.5" cy="28.5" r="27"></circle>
                      <g id="104501_twitter_bird_icon" transform="translate(11.000000, 14.000000)" fill="#3A9407" fill-rule="nonzero">
                        <path d="M36,3.43285707 C34.6765727,4.01598641 33.2522989,4.40932707 31.757871,4.58535245 C33.2837221,3.67987211 34.4536874,2.24559125 35.0054199,0.534595594 C33.578223,1.37415697 31.9982949,1.98336414 30.3153279,2.3122346 C28.9685159,0.889543888 27.048779,0 24.924426,0 C20.8459899,0 17.5392485,3.27783884 17.5392485,7.32135185 C17.5392485,7.89433981 17.6042872,8.45284009 17.7307107,8.98960883 C11.5922295,8.68391867 6.14944279,5.76972074 2.5065465,1.34011091 C1.87077523,2.42089224 1.50685098,3.67842334 1.50685098,5.02143178 C1.50685098,7.56112305 2.81054747,9.80236799 4.79239998,11.1149523 C3.58224225,11.0765599 2.44296937,10.7469651 1.44692771,10.1986062 C1.44619694,10.2290303 1.44619694,10.2601788 1.44619694,10.2913274 C1.44619694,13.8379128 3.9922051,16.7962981 7.37129286,17.4699755 C6.75159856,17.6365839 6.09901955,17.7264076 5.42524816,17.7264076 C4.94878509,17.7264076 4.48620669,17.6807713 4.03532063,17.5945696 C4.97582364,20.5029725 7.70306315,22.6203477 10.934535,22.6790228 C8.40679618,24.6428286 5.22282443,25.8134336 1.76189026,25.8134336 C1.16631143,25.8134336 0.578040314,25.7786631 0,25.710571 C3.26947202,27.7888295 7.15133061,29 11.321844,29 C24.9076183,29 32.3359113,17.8444822 32.3359113,8.16960584 C32.3359113,7.85232552 32.3293344,7.5357696 32.3154497,7.22138682 C33.7579928,6.19131238 35.0105353,4.90118399 36,3.43285707 Z" id="Path"></path>
                      </g>
                    </g>
                  </g>
                </svg>
              </a>
              <a href="https://integranxt.com/contact/">
                <svg width="50px" height="50px" viewBox="0 0 63 60" version="1.1" xmlns="http://www.w3.org/2000/svg" >
                  <title>Artboard</title>
                  <desc>Created with Sketch.</desc>
                  <g id="Artboard" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <g id="Mail" transform="translate(3.000000, 2.000000)">
                      <circle id="Oval" stroke="#A7C29D" stroke-width="3" fill="#FFFFFF" cx="28.5" cy="28.5" r="27"></circle>
                      <g id="1564504_email_letter_mail_message_icon" transform="translate(12.000000, 16.000000)" fill="#3A9407" fill-rule="nonzero">
                        <path d="M27.5,0 L5.5,0 C2.475,0 0,2.5 0,5.55555556 L0,19.4444444 C0,22.5 2.475,25 5.5,25 L27.5,25 C30.525,25 33,22.5 33,19.4444444 L33,5.55555556 C33,2.5 30.525,0 27.5,0 Z M29.7,8.05555556 L18.8375,15.4166667 C18.15,15.8333333 17.325,16.1111111 16.5,16.1111111 C15.675,16.1111111 14.85,15.8333333 14.1625,15.4166667 L3.3,8.05555556 C2.75,7.63888889 2.6125,6.80555556 3.025,6.11111111 C3.4375,5.55555556 4.2625,5.41666667 4.95,5.83333333 L15.8125,13.1944444 C16.225,13.4722222 16.9125,13.4722222 17.325,13.1944444 L28.1875,5.83333333 C28.875,5.41666667 29.7,5.55555556 30.1125,6.25 C30.3875,6.80555556 30.25,7.63888889 29.7,8.05555556 Z" id="email"></path>
                      </g>
                    </g>
                  </g>
                </svg>
              </a>
              {/* <img class="imageIcons" src={fb} alt="facebook"/>
          
          
          <img class="imageIcons" src={tw} alt="twitter"/>
            <img class="imageIcons" src={wt} alt="whatsapp"/>
            <img class="imageIcons" src={Li} alt="linked in"/> */}
              {/* <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor"
                    class="bi bi-facebook" viewBox="0 0 16 16">
                    <path
                        d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z" />
                </svg>
                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-info"
                    viewBox="0 0 16 16">
                    <path
                        d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                </svg>
             
                <svg class="icon2" xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-twitter"
                    viewBox="0 0 16 16">
                    <path
                        d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518 3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.865.115 3.23 3.23 0 0 1-.614-.057 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z" />
                </svg>
                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-google"
                    viewBox="0 0 16 16">
                    <path
                        d="M15.545 6.558a9.42 9.42 0 0 1 .139 1.626c0 2.434-.87 4.492-2.384 5.885h.002C11.978 15.292 10.158 16 8 16A8 8 0 1 1 8 0a7.689 7.689 0 0 1 5.352 2.082l-2.284 2.284A4.347 4.347 0 0 0 8 3.166c-2.087 0-3.86 1.408-4.492 3.304a4.792 4.792 0 0 0 0 3.063h.003c.635 1.893 2.405 3.301 4.492 3.301 1.078 0 2.004-.276 2.722-.764h-.003a3.702 3.702 0 0 0 1.599-2.431H8v-3.08h7.545z" />
                </svg> */}
            </div>
            <div class="footer1">
              <div class="footerText">
                {" "}
                © Copyright 2023 | Integra Software Services Pvt. Ltd.
              </div>
              <div class="footerText2">Privacy Policy | Privacy Notice – Candidates</div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
